import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { User } from 'react-feather';

import useAuth from '../../hooks/useAuth';
import { getNameInitials, getRandomColor, isLightColor } from '../../util/util';

const NavbarUser = () => {
  const { signOut, user } = useAuth();
  const userName = user ? user.name : 'Correcto User';
  const backgroundColor = getRandomColor();
  const textColor = isLightColor(backgroundColor) ? '#000000' : '#ffffff';

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <div style={{ backgroundColor, color: textColor }} className="avatar img-fluid rounded-circle me-1">
            {getNameInitials(userName)}
          </div>
          <span className="text-dark">{userName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        {user && user.company && (
          <Dropdown.Item disabled className="text-white bg-dark">
            {user['company']}
          </Dropdown.Item>
        )}
        <Dropdown.Item>
          <User size={18} className="align-middle me-2 icon-gap-right" />
          Profile
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => signOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
