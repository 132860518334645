import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import Sidebar from '../components/sidebar/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import Content from '../components/Content';
import Footer from '../components/Footer';
import CorrectoLogo from '../assets/img/c-logo.svg';
import CompanyLogo from '../assets/img/company-logo-sample.png';

import {
  companyNavItems,
  employeeNavItems,
  superAdminNavItems,
} from '../components/sidebar/sidebar_routes/sidebarRouteItems';
import { UserRole } from '../types/auth';
import useAuth from '../hooks/useAuth';

const Dashboard = ({ children }: React.PropsWithChildren) => {
  const { user } = useAuth();
  const [content, setContent] = useState(employeeNavItems);
  const logo = user?.role === UserRole.EMPLOYEE ? CorrectoLogo : CompanyLogo;
  useEffect(() => {
    const getContent = () => {
      user?.role === UserRole.EMPLOYEE
        ? setContent(employeeNavItems)
        : user?.role === UserRole.COMPANY_ADMIN
        ? setContent(companyNavItems)
        : setContent(superAdminNavItems);
    };

    getContent();
  }, [user]);

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar logo={logo} items={content} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
