import React, { useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';

import { ReactComponent as Logo } from '../../assets/img/c-logo-black.svg';
import { ReactComponent as Text } from '../../assets/img/c-text-black.svg';

import useAuth from '../../hooks/useAuth';
import { UserRole } from '../../types/auth';

function SuperAdminLogin() {
  const [userRole, setUserRole] = useState<UserRole>(UserRole.EMPLOYEE);

  const { signIn } = useAuth();

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const role = e['target']['value'];
    setUserRole(role as UserRole);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const newUser = {
      firstName: 'John',
      lastName: 'Doe',
      name: 'John Doe',
      personalNumber: '123xyz',
      username: 'johndoe',
      role: userRole,
      accessToken: '123xyz',
    };

    signIn(newUser);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <span className="me-2">
                <Logo />
              </span>
              <span className="align-middle">
                <Text />
              </span>
            </div>
            <Form onSubmit={handleSubmit}>
              <Alert className="my-3" variant="primary">
                <div className="alert-message">
                  Use <strong>company@correcto.io</strong> and <strong>demopassword</strong> to sign in
                </div>
              </Alert>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control size="lg" type="email" name="email" placeholder="Enter your email" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control size="lg" type="password" name="password" placeholder="Enter your password" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Roles</Form.Label>
                {Object.values(UserRole).map((role) => (
                  <div key={role} className=" mb-3">
                    <Form.Check
                      name="roles"
                      inline
                      type="radio"
                      id={role}
                      label={role}
                      value={role}
                      onChange={handleRoleChange}
                      checked={role === userRole}
                    />
                  </div>
                ))}
              </Form.Group>

              <div className="text-center mt-3">
                <Button type="submit" variant="primary" size="lg">
                  Sign in
                </Button>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default SuperAdminLogin;
