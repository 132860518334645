import React from 'react';
import { Pie } from 'react-chartjs-2';
import usePalette from '../../hooks/usePalette';

type Props = {
  children?: React.ReactNode;
};

const PieChart = ({ children }: Props) => {
  const palette = usePalette();

  const data = {
    labels: ['Social', 'Search Engines', 'Direct', 'Other'],
    datasets: [
      {
        data: [260, 125, 54, 146],
        backgroundColor: [palette.primary, palette.warning, palette.danger, '#E8EAED'],
        borderWidth: 5,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: false,
    cutoutPercentage: 70,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="align-self-center w-100">
      <div className="py-3">
        <div className="chart chart-xs">
          <Pie data={data} options={options} />
        </div>
      </div>

      {children}
    </div>
  );
};

export default PieChart;
