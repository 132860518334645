import React from 'react';
import { Form } from 'react-bootstrap';

type Props = { label: string; items: string[] };

function TableFilter({ label, items }: Props) {
  return (
    <div className=".d-inline-flex flex-column w-25 me-2">
      <label htmlFor={label} className=".text-dark mb-2">
        {label}
      </label>
      <Form.Select size="sm">
        {items.map((item) => (
          <option key={item} value="1">
            {item}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}

export default TableFilter;
