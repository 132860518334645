import React from 'react';

function HorizontalRule() {
  return (
    <hr
      style={{
        height: '3px',
        border: 'none',
        color: '#000',
        backgroundColor: '#000',
        margin: 0,
        marginBottom: '1rem',
      }}
    />
  );
}

export default HorizontalRule;
