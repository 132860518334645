import React from 'react';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { FileMinus } from 'react-feather';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import ReportLine from '../../components/report/ReportLine';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Settings',
  },
];

function SettingsPage() {
  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Settings" />
      <CardComponent>
        <Tabs defaultActiveKey="company" className="mb-3">
          <Tab eventKey="company" title="Company Details">
            <PagesHeader pageTitle="Company Details" />
            <div>
              <ReportLine type="LABEL_VALUE" label="Company Name" value="Correcto Technology AB" />
              <ReportLine type="LABEL_VALUE" label="Registration Number" value="L-697v" />
              <ReportLine type="LABEL_VALUE" label="Address" value="2062 Wiseman Street" />
              <ReportLine type="LABEL_VALUE" label="Mail 1" value="2062 Wiseman Street" />
              <ReportLine type="LABEL_VALUE" label="Mail 2" value="2062 Wiseman Street" />
            </div>
          </Tab>
          <Tab eventKey="integrations" title="Integrations">
            <PagesHeader pageTitle="Integrations" />
            {/* component start */}
            <div>
              <p className="h4">Fortnox</p>
              <p>
                Start using xxxxxxxxxxxxxxxx and more with data from your Fortnox account. An API key from Fortnox is
                required.
              </p>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Fortnox integration API key:</Form.Label>
                  <div className="d-flex gap-3">
                    <Form.Control
                      size="lg"
                      type="text"
                      name="key"
                      className="w-50"
                      placeholder="e.g. 5a1c0001b-a2a3-2849-6sdi-a482c9bb39d7"
                    />
                    <Button type="button">Verify</Button>
                  </div>
                </Form.Group>
              </Form>
            </div>

            <div>
              <p className="h4">Hogia</p>
              <p>
                Start using xxxxxxxxxxxxxxxx and more with data from your Hogia account. An API key from Hogia is
                required.
              </p>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Fortnox integration API key:</Form.Label>
                  <div className="d-flex gap-3">
                    <Form.Control
                      size="lg"
                      type="text"
                      name="key"
                      className="w-50"
                      placeholder="e.g. 129381923-1823719231237"
                    />
                    <Button type="button">Verify</Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
            {/* component end */}
          </Tab>
          <Tab eventKey="proxy" title="Proxy">
            <PagesHeader pageTitle="Proxy" />
            <div>
              <p className="h4">Proxy from company</p>
              <p>TDB</p>
            </div>
          </Tab>
          <Tab eventKey="agreement" title="Agreement">
            <PagesHeader pageTitle="Agreement" />
            <div>
              <p className="h4">Agreement</p>
              <p>TDB</p>
            </div>
          </Tab>
          <Tab eventKey="invoices" title="Invoices">
            <PagesHeader pageTitle="Invoices" />

            <div className="cursor-pointer mb-3">
              <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Jan 2022</span>
            </div>
            <div className="cursor-pointer mb-3">
              <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Feb 2022</span>
            </div>
            <div className="cursor-pointer mb-3">
              <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Mar 2022</span>
            </div>
            <div className="cursor-pointer mb-3">
              <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Apr 2022</span>
            </div>
          </Tab>
          <Tab eventKey="security" title="Security">
            <PagesHeader pageTitle="Security" />
            <div>
              <p className="h4">Security</p>
              <p>TDB</p>
            </div>
          </Tab>
        </Tabs>
      </CardComponent>
    </div>
  );
}

export default SettingsPage;
