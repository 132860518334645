import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import HorizontalRule from '../../components/base/HorizontalRule';
import PagesHeader from '../../components/base/PagesHeader';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Change Pension Funds',
  },
];

function PensionFundsPage() {
  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Change Pension Funds" />
      <CardComponent>
        <div className="w-50 my-5">
          <CardComponent>
            <p className="h3">Avanza Future</p>
            <div className="d-flex justify-content-between mt-5">
              <p>Do you want to change funds to</p>
              <p>Yes or No</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Avanza Super Pakistan fund? </p>
              <p>Yes</p>
            </div>
            <HorizontalRule />
            <div className="d-flex justify-content-between">
              <p>Total Value </p>
              <p>15260</p>
            </div>
            <HorizontalRule />
            <Button variant="success" className="d-block ml-auto">
              Confirm
            </Button>
          </CardComponent>
        </div>
      </CardComponent>
    </Container>
  );
}

export default PensionFundsPage;
