import { API_VERSION, COMPANY_EMPLOYEE_ENDPOINT } from '../../../../util/constants';

import { get } from '../../../../util/httpService';
import { CompanyEmployeeType } from './types';

const getCompanyEmploees = async () => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_ENDPOINT}`;
  const user: CompanyEmployeeType[] = await get(endpoint);

  return user;
};

export { getCompanyEmploees };
