import React from 'react';
import { CompanyOverviewProjectType } from '../../../types/base/TableTypes';

type Props = {
  values: CompanyOverviewProjectType['data'];
};

function TableCompanyOverviewProject({ values }: Props) {
  return (
    <React.Fragment>
      {values.map((item, i) => (
        <tr key={i}>
          <td>{item.name}</td>
          <td>{item['start Date']}</td>
          <td>{item['end Date']}</td>
          <td>{item.status}</td>
          <td>{item.assignee}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default TableCompanyOverviewProject;
