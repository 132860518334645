import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { FilePlus } from 'react-feather';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import TableComponent from '../../components/table/TableComponent';
import { SuperAdminPensionPolicyData, SuperAdminPensionPolicyHeader } from '../../dummy/data';
import { IBreadCrumb } from '../../types/base/BreadCrumb';
import { SuperAdminPensionPoliciesDataType } from '../../types/base/TableTypes';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Pension policy',
  },
];

const tabledata: SuperAdminPensionPoliciesDataType = {
  type: 'SUPER_ADMIN_PENSION_POLICY',
  data: SuperAdminPensionPolicyData,
};

function PensionPoliciesPage() {
  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between align-items-center">
        <PagesHeader pageTitle="Pension Policy" />
        <Button variant="primary">
          <FilePlus className="icon-gap-right" />
          Add New Policy
        </Button>
      </div>

      <CardComponent>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ac sapien non mauris congue consequat ut ut
          eros. Vestibulum rhoncus mi nec leo blandit, sit amet mattis diam mattis.
        </p>

        <TableComponent header={SuperAdminPensionPolicyHeader} tableItem={tabledata} />
      </CardComponent>
    </Container>
  );
}

export default PensionPoliciesPage;
