import React from 'react';
import { Form } from 'react-bootstrap';
import CardComponent from '../base/CardComponent';
import PagesHeader from '../base/PagesHeader';

function SalaryInfo() {
  return (
    <div>
      <PagesHeader pageTitle="Salary Info" />
      <CardComponent>
        <Form>
          <div className="d-flex mb-3 w-75">
            <Form.Group className="me-3 w-25">
              <Form.Label>Salary (kr)</Form.Label>
              <Form.Control disabled placeholder="Enter Salary" name="Salary" value="56 000" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Schedule</Form.Label>
              <Form.Select name="Schedule" disabled placeholder="Enter Schedule">
                <option>Full time</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Paid Holidays</Form.Label>
              <Form.Control disabled placeholder="Enter Paid Holidays" name="Paid Holidays" value="28" />
            </Form.Group>
          </div>
        </Form>
      </CardComponent>
    </div>
  );
}

export default SalaryInfo;
