import React from 'react';
import { ITableHeader } from '../../types/base/TableTypes';

type Props = {
  header: ITableHeader[];
};

function TableHeader({ header }: Props) {
  return (
    <thead>
      <tr>
        {header.map((dataItem, i) => (
          <th key={i} className={`${dataItem.className} text-dark`}>
            {dataItem.label}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
