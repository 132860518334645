import React from 'react';
import { Clipboard, Edit2, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { SuperAdminPensionPoliciesDataType } from '../../../types/base/TableTypes';

type Props = {
  values: SuperAdminPensionPoliciesDataType['data'];
};

function SuperAdminPensionPolicies({ values }: Props) {
  return (
    <React.Fragment>
      {values.data.map((item, i) => (
        <tr key={i}>
          <td>
            <Link to={`/pension-policies/${item['name']}?edit=${item['edit']}`}>{item['name']}</Link>
          </td>
          <td>{item['version']}</td>
          <td>{item['employeeType']}</td>
          <td>{item['effectiveFrom']}</td>
          <td>{item['status']}</td>
          <td style={{ width: '1%', whiteSpace: 'nowrap' }}>{<Edit2 />}</td>
          <td style={{ width: '1%', whiteSpace: 'nowrap', paddingRight: 30 }}>{<Clipboard />}</td>
          <td style={{ width: '1%', whiteSpace: 'nowrap' }}>{<Trash2 />}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default SuperAdminPensionPolicies;
