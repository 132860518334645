import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import HorizontalRule from '../../components/base/HorizontalRule';
import PagesHeader from '../../components/base/PagesHeader';
import LineChart from '../../components/charts/LineChart';
import TableComponent from '../../components/table/TableComponent';
import { CompanyPensionCostData, CompanyPensionCostHeader } from '../../dummy/data';
import { IBreadCrumb } from '../../types/base/BreadCrumb';
import { CompanyPensionCostDataType } from '../../types/base/TableTypes';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Pension Cost',
  },
];

const tabledata: CompanyPensionCostDataType = {
  type: 'COMPANY_PENSION_COST',
  data: CompanyPensionCostData,
};

function PensionCostPage() {
  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Pension Cost" />
      <CardComponent>
        <Form className="mb-3">
          <div className="d-flex align-items-end flex-wrap gap-5">
            <Button type="button" variant="primary">
              <ChevronLeft className="icon-gap-right" /> Invoices
            </Button>

            <Form.Group className="w-25">
              <Form.Label>Period</Form.Label>
              <Form.Select name="Period" placeholder="Enter Period">
                <option>Rolling 6 months</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>

            <Button type="button" variant="primary">
              Invoices <ChevronRight className="icon-gap-left" />
            </Button>
          </div>
        </Form>
        <HorizontalRule />
        <div className="mb-3">
          <LineChart />
        </div>
        <HorizontalRule />
        <PagesHeader pageTitle="Companies" />
        <TableComponent header={CompanyPensionCostHeader} tableItem={tabledata} />
      </CardComponent>
    </div>
  );
}

export default PensionCostPage;
