import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FileMinus } from 'react-feather';
import CardComponent from '../base/CardComponent';

function ReportTab() {
  return (
    <div>
      <CardComponent>
        <div className="py-3">
          <Row className="mb-3">
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" />{' '}
                <span className="text-primary fw-bolder">Pension cost (PDF)</span>
              </div>
            </Col>
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" />{' '}
                <span className="text-primary fw-bolder">Salary cost (PDF)</span>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" />{' '}
                <span className="text-primary fw-bolder">Pension cost (XLS)</span>
              </div>
            </Col>
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" />{' '}
                <span className="text-primary fw-bolder">Salary cost (XLS)</span>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Other report</span>
              </div>
            </Col>
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Other report</span>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Other report</span>
              </div>
            </Col>
            <Col md="6" xl>
              <div className="cursor-pointer">
                <FileMinus className="icon-gap-right" /> <span className="text-primary fw-bolder">Other report</span>
              </div>
            </Col>
          </Row>
        </div>
      </CardComponent>
    </div>
  );
}

export default ReportTab;
