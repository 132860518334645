import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import HorizontalRule from '../../components/base/HorizontalRule';
import PagesHeader from '../../components/base/PagesHeader';
import LineChart from '../../components/charts/LineChart';
import ReportHeader from '../../components/report/ReportHeader';
import ReportLine from '../../components/report/ReportLine';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Business Analysis',
  },
];

function BusinessAnalysisPage() {
  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Business Analysis" />
      <Row>
        <CardComponent title="Temp Title / Dummy Data">
          <LineChart />
        </CardComponent>
      </Row>
      <Row>
        <PagesHeader pageTitle="Finance" />
        <Col md="6" xl>
          <CardComponent>
            <ReportHeader btn={{ text: 'Prev Month' }} title="November" />
            <div className="mt-3">
              <ReportLine label="Salary (Code 12)" value="570000" tooltip="salary tooltip" type="LABEL_VALUE" />
              <ReportLine label="Sickleave" value="-20000" type="LABEL_VALUE" />
              <ReportLine label="Salary (Code 12)" value="570000" type="LABEL_VALUE" />
            </div>
            <HorizontalRule />
            <ReportLine
              label="Total Pension Offset Salary"
              value="560240"
              tooltip="total pension tooltip"
              type="LABEL_VALUE"
            />
            <ReportLine label="Pension Offset" value="55211" type="LABEL_VALUE" />
            <ReportLine label="Salary Change" value="0" type="LABEL_VALUE" />
            <HorizontalRule />
            <ReportLine label="Corrected Pension Offset" value="-91111" type="LABEL_VALUE" />
            <ReportLine label="Number of Employees" value="9" type="LABEL_VALUE" />
            <HorizontalRule />
            <Button variant="primary" className="my-3">
              <ChevronRight className="icon-gap-right" /> Invoices
            </Button>
          </CardComponent>
        </Col>
        <Col md="6" xl>
          <CardComponent>
            <ReportHeader btn={{ text: 'Next Month' }} title="November" />
            <div className="mt-3">
              <ReportLine label="Salary (Code 12)" value="570000" tooltip="salary tooltip" type="LABEL_VALUE" />
              <ReportLine label="Sickleave" value="-20000" type="LABEL_VALUE" />
              <ReportLine label="Salary (Code 12)" value="570000" type="LABEL_VALUE" />
            </div>
            <HorizontalRule />
            <ReportLine
              label="Total Pension Offset Salary"
              value="560240"
              tooltip="total pension tooltip"
              type="LABEL_VALUE"
            />
            <ReportLine label="Pension Offset" value="55211" type="LABEL_VALUE" />
            <ReportLine label="Salary Change" value="0" type="LABEL_VALUE" />
            <HorizontalRule />
            <ReportLine label="Corrected Pension Offset" value="-91111" type="LABEL_VALUE" />
            <ReportLine label="Number of Employees" value="9" type="LABEL_VALUE" />
            <HorizontalRule />
            <Button variant="primary" className="my-3">
              <ChevronRight /> Invoices
            </Button>
          </CardComponent>
        </Col>
      </Row>
    </Container>
  );
}

export default BusinessAnalysisPage;
