import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { DollarSign, ShoppingBag } from 'react-feather';

import Breadcrumb from '../../components/base/Breadcrumb';
import PagesHeader from '../../components/base/PagesHeader';
import Statistics from '../../components/base/Statistics';
import TableComponent from '../../components/table/TableComponent';
import WelcomeBox from '../../components/base/WelcomeBox';

import BarChart from '../../components/charts/BarChart';
import PieChart from '../../components/charts/PieChart';

import { IBreadCrumb } from '../../types/base/BreadCrumb';

import CardComponent from '../../components/base/CardComponent';
import {
  EmployeeOverviewPieChartHeader,
  EmployeeOverviewPieChartData,
  EmployeeOveriewProjectsHeader,
  EmployeeOverviewProjectData,
} from '../../dummy/data';
import { EmployeeOverviewPieChartType, EmployeeOverviewProjectType } from '../../types/base/TableTypes';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
  },
  {
    name: 'Dashboard',
    href: '#',
  },
];

const piechartData: EmployeeOverviewPieChartType = {
  data: EmployeeOverviewPieChartData,
  type: 'EMPLOYEE_OVERVIEW_PIECHART',
};

const projectData: EmployeeOverviewProjectType = {
  type: 'EMPLOYEE_OVERVIEW_PROJECT',
  data: EmployeeOverviewProjectData,
};

function OverviewPage() {
  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Dashboard" showCalender={true} showRefreshBtn={true} />
      <Row>
        <Col md="6" xl className="d-flex">
          <WelcomeBox />
        </Col>
        <Col md="6" xl className="d-flex">
          <Statistics
            value="$ 24.300"
            label="Total Earnings"
            percent="+5.35%"
            sinceTime="Since last week"
            Icon={DollarSign}
          />
        </Col>
        <Col md="6" xl className="d-flex">
          <Statistics
            value="43"
            label="Pending Orders"
            percent="-4.25%"
            sinceTime="Since last week"
            Icon={ShoppingBag}
          />
        </Col>
        <Col md="6" xl className="d-flex">
          <Statistics
            value="$ 18.700"
            label="Total Revenue"
            percent="+8.65%"
            sinceTime="Since last week"
            Icon={DollarSign}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="8" className="d-flex">
          <CardComponent title="Temp Title / Dummy Data">
            <BarChart />
          </CardComponent>
        </Col>
        <Col lg="4" className="d-flex">
          <CardComponent title="Temp Title / Dummy Data">
            <PieChart>
              <TableComponent header={EmployeeOverviewPieChartHeader} tableItem={piechartData} />
            </PieChart>
          </CardComponent>
        </Col>
      </Row>
      <Row>
        <CardComponent title="Temp Title / Dummy Data">
          <TableComponent header={EmployeeOveriewProjectsHeader} tableItem={projectData} />
        </CardComponent>
      </Row>
    </Container>
  );
}

export default OverviewPage;
