import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import ReportLine from '../../components/report/ReportLine';
import ReportTitle from '../../components/report/ReportTitle';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Reports',
  },
];

function ReportsPage() {
  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Reports" />
      <CardComponent>
        <div className="m-3">
          <Row>
            <Col md="6" lg>
              <div className="mb-3">
                <ReportTitle title="P&L + BS" link={true} />
                <ReportLine text="Salary cost" icon={true} type="ICON_VALUE" />
                <ReportLine text="Special Payroll Tax" icon={true} type="ICON_VALUE" />
                <ReportLine text="Holiday Debt" icon={true} type="ICON_VALUE" />
                <ReportLine text="Accounting Records" icon={true} type="ICON_VALUE">
                  Change Special Payroll Tax
                </ReportLine>
                <ReportLine text="Balance sheet/Income statement " icon={true} type="ICON_VALUE" />
              </div>
              <div className="mb-3">
                <ReportTitle title="Employee" link={true} />
                <ReportLine text="Employee contract" icon={true} type="ICON_VALUE">
                  See contract in PDF
                </ReportLine>
                <ReportLine text="Pension Plan per  Employee" icon={true} type="ICON_VALUE" />
              </div>
              <div className="mb-3">
                <ReportTitle title="Cost Simulation" link={true} />
                <ReportLine text="Simulation of salary changed  (%)" icon={true} type="ICON_VALUE" />
                <ReportLine text="Simulation of Employees (personal + %)" icon={true} type="ICON_VALUE" />
                <ReportLine text="Holiday Pay Liability Simulation" icon={true} type="ICON_VALUE" />
                <ReportLine text="Special Payroll Simulation " icon={true} type="ICON_VALUE">
                  Month / Year
                </ReportLine>
              </div>
            </Col>
            <Col md="6" lg>
              <div className="mb-3">
                <ReportTitle title="Salary" link={true} />
                <ReportLine text="Salary transaction" icon={true} type="ICON_VALUE" />
                <ReportLine text="Salary  Codes" icon={true} type="ICON_VALUE" />
                <ReportLine text="Sick leave" icon={true} type="ICON_VALUE" />
              </div>

              <div className="mb-3">
                <ReportTitle title="Holiday Debt" link={true} />
                <ReportLine text="Holiday debt" icon={true} type="ICON_VALUE">
                  Company / Employees
                </ReportLine>
                <ReportLine text="Tax Holiday debt" icon={true} type="ICON_VALUE">
                  Company / Employees
                </ReportLine>
                <ReportLine text="Annual Report" icon={true} type="ICON_VALUE">
                  Auditor / Monthly Closing
                </ReportLine>
              </div>

              <div className="mb-3">
                <ReportTitle title="Fora & Collectum" link={true} />
                <ReportLine text="Report to Fora" icon={true} type="ICON_VALUE" />
                <ReportLine text="Report to Collectum" icon={true} type="ICON_VALUE" />
              </div>

              <div className="mb-3">
                <ReportTitle title="End of year & Auditor's Document" link={true} />
                <ReportLine text="Calculate Special  Payroll Tax 25XX + 75XX" icon={true} type="ICON_VALUE" />
                <ReportLine text="Basis for INK2" icon={true} type="ICON_VALUE">
                  Create a document that has Tax closing (Karnow, BL etc)
                </ReportLine>
              </div>

              <div className="mb-3">
                <ReportTitle title="Payroll Mapping" link={true} />
                <ReportLine text="Total Salary + Pension" icon={true} type="ICON_VALUE" />
                <ReportLine text="Total Salary " icon={true} type="ICON_VALUE" />
                <ReportLine text="Total Pension" icon={true} type="ICON_VALUE" />
              </div>

              <div className="mb-3">
                <ReportTitle title="Other Collective Agreement Reports" />
                <ReportLine text="SPV" icon={true} type="ICON_VALUE" />
                <ReportLine text="KPA" icon={true} type="ICON_VALUE" />
                <ReportLine text="BTP1/BTB2" icon={true} type="ICON_VALUE" />
                <ReportLine text="ITP" icon={true} type="ICON_VALUE" />
                <ReportLine text="Etcetra" icon={true} type="ICON_VALUE" />
              </div>
            </Col>
          </Row>
        </div>
      </CardComponent>
    </div>
  );
}

export default ReportsPage;
