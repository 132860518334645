import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Pension Policies',
  },
];

function PensionPoliciesPage() {
  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Pension Policies" />
      <CardComponent>
        <Tabs defaultActiveKey="pp01" className="mb-3">
          <Tab eventKey="pp01" title="PP01">
            PP01 Content TBD
          </Tab>
          <Tab eventKey="itp1" title="ITP1">
            ITP1 Content TBD
          </Tab>
          <Tab eventKey="itp2" title="ITP2">
            ITP2 Content TBD
          </Tab>
        </Tabs>
      </CardComponent>
    </div>
  );
}

export default PensionPoliciesPage;
