import { SidebarItemsType } from '../../../types/sidebar';

import { Activity, DollarSign, Users, FileMinus, Settings, Briefcase, Grid, Sliders } from 'react-feather';

const companySidebarMenuItems = [
  {
    href: '/dashboard',
    icon: Grid,
    title: 'Overview',
  },
  {
    href: '/business-analysis',
    icon: Activity,
    title: 'Business Analysis',
  },
  {
    href: '/employees',
    icon: Users,
    title: 'Employees',
  },
  {
    href: '/pension-cost',
    icon: DollarSign,
    title: 'Pension Cost',
  },
  {
    href: '/pension-policies',
    icon: Briefcase,
    title: 'Pension Policies',
  },
  {
    href: '/reports',
    icon: FileMinus,
    title: 'Reports',
  },
  {
    href: '/settings',
    icon: Settings,
    title: 'Settings',
  },
] as SidebarItemsType[];

const superAdminSidebarMenuItems = [
  {
    href: '/dashboard',
    icon: Grid,
    title: 'Overview',
  },
  {
    href: '/company-clients',
    icon: Briefcase,
    title: 'Company Client',
  },
  {
    href: '/personal-clients',
    icon: Users,
    title: 'Personal Clients',
  },
  {
    href: '/pension-policies',
    icon: Settings,
    title: 'Pension Policies',
  },
  // {
  //   href: '/admin-settings',
  //   icon: Settings,
  //   title: 'Settings',
  // },
] as SidebarItemsType[];

const employeeSidebarMenuItems = [
  {
    href: '/dashboard',
    icon: Grid,
    title: 'Overview',
  },
  {
    href: '/pension-statement',
    icon: Activity,
    title: 'Pension Statement',
  },
  {
    href: '/pension-funds',
    icon: Sliders,
    title: 'Change Pension Funds',
  },
  {
    href: '/salary-check',
    icon: DollarSign,
    title: 'Salary Check',
  },
  {
    href: '/pension-paid',
    icon: Briefcase,
    title: 'Pension Paid',
  },
  {
    href: '/settings',
    icon: Settings,
    title: 'Settings',
  },
] as SidebarItemsType[];

const companyNavItems = [
  {
    title: '\n',
    pages: companySidebarMenuItems,
  },
];

const superAdminNavItems = [
  {
    title: '\n',
    pages: superAdminSidebarMenuItems,
  },
];

const employeeNavItems = [
  {
    title: '\n',
    pages: employeeSidebarMenuItems,
  },
];

export { companyNavItems, superAdminNavItems, employeeNavItems };
