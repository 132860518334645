import React, { useEffect, useState } from 'react';
import { getCompanyEmploees } from '../../api/service/company/employees/service';
import { CompanyEmployeeType } from '../../api/service/company/employees/types';

import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import TableComponent from '../../components/table/TableComponent';
import TableFilter from '../../components/table/TableFilter';
import { CompanyEmployeesHeader } from '../../dummy/data';
import { IBreadCrumb } from '../../types/base/BreadCrumb';
import { CompanyEmployeesDataType } from '../../types/base/TableTypes';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Employees',
  },
];

function EmployeesPage() {
  const [employeeData, setEmployeeData] = useState<CompanyEmployeeType[] | null>(null);

  const tabledata: CompanyEmployeesDataType = {
    type: 'COMPANY_EMPLOYEE',
    data: employeeData,
  };

  useEffect(() => {
    const getEmployeers = async () => {
      const user = await getCompanyEmploees();
      setEmployeeData(user);
    };

    getEmployeers();
  }, []);

  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Employees" />

      <CardComponent>
        <div className="d-flex flex-wrap mb-4">
          <TableFilter label="Employees" items={['All', 'Some', 'None']} />
          <TableFilter label="Form of employment" items={['All', 'Some', 'None']} />
        </div>
        <TableComponent header={CompanyEmployeesHeader} tableItem={tabledata} />
      </CardComponent>
    </div>
  );
}

export default EmployeesPage;
