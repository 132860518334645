import React from 'react';
import { Card } from 'react-bootstrap';
import SignIn from '../../components/auth/SignIn';

import { ReactComponent as Logo } from '../../assets/img/c-logo-black.svg';
import { ReactComponent as Text } from '../../assets/img/c-text-black.svg';

const SignInPage = () => {
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <span className="me-2">
                <Logo />
              </span>
              <span className="align-middle">
                <Text />
              </span>
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
