import React from 'react';
import { Link } from 'react-router-dom';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

type Props = {
  navItem: IBreadCrumb[];
};

function Breadcrumb({ navItem }: Props) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {navItem.map((item) => (
          <li key={item.name} className="breadcrumb-item " aria-current="page">
            {item.href ? <Link to={item.href}>{item.name}</Link> : item.name}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
