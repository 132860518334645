import React from 'react';
// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Auth
import AuthGuard from './components/guards/AuthGuard';

import SignIn from './pages/auth/SignIn';

// Company Pages
import CompanyOverview from './pages/company/Overview';
import CompanyBusinessAnalysis from './pages/company/BusinessAnalysis';
import CompanyEmployees from './pages/company/Employees';
import CompanyEmployeeDetails from './pages/company/EmployeeDetails';
import CompanyPensionCost from './pages/company/PensionCost';
import CompanyPolicies from './pages/company/PensionPolicies';
import CompanyReports from './pages/company/Reports';
import CompanyReportDetails from './pages/company/ReportDetails';
import CompanySettings from './pages/company/Settings';

// Super Admin Pages
import SuperAdminOverview from './pages/superAdmin/Overview';
import SuperAdminCompanyClients from './pages/superAdmin/CompanyClients';
import SuperAdminPersonalClients from './pages/superAdmin/PersonalClients';
import SuperAdminPensionPolicies from './pages/superAdmin/PensionPolicies';
import SuperAdminSettings from './pages/superAdmin/Settings';

// Employee Pages
import EmployeeOverview from './pages/employee/Overview';
import EmployeePensionStatement from './pages/employee/PensionStatement';
import EmployeePensionFunds from './pages/employee/PensionFunds';
import EmployeeSalaryCheck from './pages/employee/SalaryCheck';
import EmployeePensionPaid from './pages/employee/PensionPaid';
import EmployeeSettings from './pages/employee/Settings';

// Base Pages
import Page404 from './pages/base/Page404';
import PensionPolicyDetails from './pages/superAdmin/PensionPoliciesDetails';
import SuperAdminLogin from './pages/superAdmin/SuperAdminLogin';

const routesCompany = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/invitation',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CompanyOverview />,
      },
    ],
  },
  {
    path: '/business-analysis',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <CompanyBusinessAnalysis />,
      },
    ],
  },
  {
    path: '/employees',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CompanyEmployees />,
      },
      {
        path: ':id',
        element: <CompanyEmployeeDetails />,
      },
    ],
  },
  {
    path: '/pension-cost',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CompanyPensionCost />,
      },
    ],
  },
  {
    path: '/pension-policies',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CompanyPolicies />,
      },
    ],
  },
  {
    path: '/reports',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CompanyReports />,
      },
      {
        path: ':name',
        element: <CompanyReportDetails />,
      },
    ],
  },
  {
    path: '/settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CompanySettings />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

const routesSuperAdmin = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/invitation',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/superadmin',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SuperAdminLogin />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SuperAdminOverview />,
      },
    ],
  },
  {
    path: '/company-clients',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SuperAdminCompanyClients />,
      },
    ],
  },
  {
    path: '/personal-clients',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SuperAdminPersonalClients />,
      },
    ],
  },
  {
    path: '/pension-policies',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <SuperAdminPensionPolicies />,
      },
      {
        path: ':name',
        element: <PensionPolicyDetails />,
      },
    ],
  },
  {
    path: '/correcto-admin',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <SuperAdminSettings />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

const routesEmployee = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/invitation',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <EmployeeOverview />,
      },
    ],
  },
  {
    path: '/pension-statement',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <EmployeePensionStatement />,
      },
    ],
  },
  {
    path: '/pension-funds',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <EmployeePensionFunds />,
      },
    ],
  },
  {
    path: '/salary-check',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <EmployeeSalaryCheck />,
      },
    ],
  },
  {
    path: '/pension-paid',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <EmployeePensionPaid />,
      },
    ],
  },
  {
    path: '/settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <EmployeeSettings />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export { routesCompany, routesSuperAdmin, routesEmployee };
