import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import illustration from '../../assets/img/illustrations/customer-support.png';

function WelcomeBox() {
  return (
    <Card className="illustration flex-fill">
      <Card.Body className="p-0 d-flex flex-fill">
        <Row className="g-0 w-100">
          <Col xs="6">
            <div className="illustration-text p-3 m-1">
              <h4 className="illustration-text">Welcome back User!</h4>
              <p className="mb-0">AppStack Dashboard</p>
            </div>
          </Col>
          <Col xs={6} className="align-self-end text-end">
            <img src={illustration} alt="Customer Support" className="img-fluid illustration-img" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default WelcomeBox;
