import React, { useState, useEffect } from 'react';
import { Col, Row, Container, Button, Modal, Form } from 'react-bootstrap';
import { Plus } from 'react-feather';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';

import { IBreadCrumb } from '../../types/base/BreadCrumb';

import { PersonalClientsHeader } from '../../dummy/data';
import { CompanyClientDataType } from '../../types/base/TableTypes';

import TableComponent from '../../components/table/TableComponent';
import ModalsHeader from '../../components/base/ModalsHeader';
import { GetCompanyClientType } from '../../api/service/superAdmin/company-clients/types';
import { getCompanyClients } from '../../api/service/superAdmin/company-clients/service';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
  },
  {
    name: 'Personal Clients',
  },
];

const colors = [
  {
    name: 'New Personal Client',
    value: 'primary',
  },
];

const CenteredModal = () => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState<any>(() => initOpenModals());

  const toggle = (index: any) => {
    setOpenModals((openModals: { [x: string]: any }) => Object.assign({}, openModals, { [index]: !openModals[index] }));
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button variant={color.value} onClick={() => toggle(index)} className="me-1">
            <Plus className="icon-gap-right" />
            {color.name}
          </Button>
          <Modal show={openModals[index]} onHide={() => toggle(index)} centered className="modal-box">
            <Modal.Header closeButton>
              <ModalsHeader pageTitle="New Personal Client" />
            </Modal.Header>
            <Modal.Body className="m-2">
              <Form>
                <div className="form-section mb-3">
                  <h4 className="mb-2">Personal Clients</h4>
                  <Row className="mb-3">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Organization number (10 digits)</Form.Label>
                        <div className="d-flex gap-3">
                          <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" />
                          <Button
                            variant="primary"
                            onClick={() => toggle(index)}
                            className="d-flex align-items-center w-100"
                          >
                            <Plus /> Fetch name
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Company name</Form.Label>
                        <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control size="lg" type="text" name="policy-name" placeholder="Address" />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Postcode </Form.Label>
                        <Form.Control size="lg" type="text" name="policy-name" placeholder="Postcode" />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control size="lg" type="text" name="policy-name" placeholder="City" />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <Form.Control size="lg" type="text" name="policy-name" placeholder="Country" />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="form-section mb-3">
                  <h4 className="mb-2">Company representative</h4>
                  <Row className="mb-3">
                    <Col md="5">
                      <Form.Group>
                        <Form.Label>Personal number (10 digits)</Form.Label>
                        <div className="d-flex gap-3">
                          <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" />
                          <Button
                            variant="primary"
                            onClick={() => toggle(index)}
                            className="d-flex align-items-center w-100"
                          >
                            <Plus /> Fetch name
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="policy-name"
                          placeholder="Enter Policy Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="policy-name"
                          placeholder="Enter Policy Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="form-section mb-3">
                  <h4 className="mb-2">Admin details</h4>
                  <Row className="mb-3">
                    <Col md="5">
                      <Form.Group>
                        <Form.Label>Personal number</Form.Label>
                        <div className="d-flex gap-3">
                          <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" />
                          <Button
                            variant="primary"
                            onClick={() => toggle(index)}
                            className="d-flex align-items-center w-100"
                          >
                            <Plus /> Fetch name
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="policy-name"
                          placeholder="Enter Policy Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="policy-name"
                          placeholder="Enter Policy Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="5">
                      <Form.Group>
                        <Form.Label>Personal number</Form.Label>
                        <div className="d-flex gap-3">
                          <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" />
                          <Button
                            variant="primary"
                            onClick={() => toggle(index)}
                            className="d-flex align-items-center w-100"
                          >
                            <Plus /> Fetch name
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="policy-name"
                          placeholder="Enter Policy Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="policy-name"
                          placeholder="Enter Policy Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-between w-100">
                <Button variant="secondary" onClick={() => toggle(index)}>
                  <Plus />
                  Cancel
                </Button>

                <div className="d-flex gap-3">
                  <Button variant="secondary" onClick={() => toggle(index)}>
                    <Plus />
                    Save as draft
                  </Button>
                  <Button variant="primary" onClick={() => toggle(index)}>
                    <Plus />
                    Save company client and send all invites
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

function PersonalClientsPage() {
  const [companyData, setCompanyData] = useState<GetCompanyClientType | null>(null);

  const tabledata: CompanyClientDataType = {
    type: 'SUPER_ADMIN_COMPANY_CLIENT',
    data: companyData,
  };

  useEffect(() => {
    const apiCall = async () => {
      const user = await getCompanyClients();
      setCompanyData(user);
    };

    apiCall();
  }, []);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader pageTitle="Personal Clients" />
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Link to="/personal-clients">
            <CenteredModal />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex">
          <CardComponent>
            <TableComponent header={PersonalClientsHeader} tableItem={tabledata} />
          </CardComponent>
        </Col>
      </Row>
    </Container>
  );
}

export default PersonalClientsPage;
