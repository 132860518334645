import React from 'react';
import { useLocation } from 'react-router-dom';

import reduceChildRoutes from '../sidebar_routes/reduceChildRoutes';
import { SidebarItemsType } from '../../../types/sidebar';

interface SidebarNavListProps {
  depth: number;
  pages: SidebarItemsType[];
}

const SidebarNavList = (props: SidebarNavListProps) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    [] as JSX.Element[],
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
