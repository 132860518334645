import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { ChevronRight, FileMinus, Save } from 'react-feather';
import CardComponent from '../base/CardComponent';
import HorizontalRule from '../base/HorizontalRule';
import PagesHeader from '../base/PagesHeader';

function GeneralInfo() {
  return (
    <div>
      <PagesHeader pageTitle="General Info" />
      <CardComponent>
        <Form>
          <div className="d-flex mb-5 w-75">
            <Form.Group className="me-3 w-25">
              <Form.Label>Salary Change</Form.Label>
              <Form.Select name="Salary Change" placeholder="Enter Salary Change">
                <option>X % (like 2% * 56000 SEK)</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Start date </Form.Label>
              <Form.Control disabled type="date" placeholder="Enter Start Date" name="Start Date" value="2018-07-22" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>No label </Form.Label>
              <Form.Select name="Salary Change" placeholder="Enter Salary Change">
                <option>Y SEK (3000 SEK)</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>
          </div>

          <PagesHeader pageTitle="Pension Agreement" />

          <div className="d-flex mb-5 w-75">
            <Form.Group className="me-3 w-auto">
              <Form.Label>Pension agreement type </Form.Label>
              <Form.Select name="Pension agreement type" placeholder="Enter Pension agreement type">
                <option>Company Policy (White collar)</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="me-3 w-auto">
              <Form.Label>Company policy </Form.Label>
              <Form.Select name="Company policy" placeholder="Enter Company policy">
                <option>Company Policy (White collar)</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>
          </div>

          <PagesHeader pageTitle="Pension company" />

          <div className="d-flex mb-5 w-75">
            <Form.Group className="me-3 w-auto">
              <Form.Label>Right to Chose Pension Company? </Form.Label>
              <Form.Select name="Right to Chose Pension Company?" placeholder="Enter Right to Chose Pension Company?">
                <option>Yes/No/From Prefered</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="me-3">
              <Form.Label>Pension Company (YES)</Form.Label>
              <Form.Select name="Pension Company (YES)" placeholder="Enter Pension Company (YES)">
                <option>Avanza</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Start date </Form.Label>
              <Form.Control disabled type="date" placeholder="Enter Start Date" name="Start Date" value="2018-07-22" />
            </Form.Group>

            <Form.Group className="me-3">
              <Form.Label>Pension cost per month</Form.Label>
              <Form.Control
                disabled
                placeholder="Enter Pension cost per month"
                name="Pension cost per month"
                value="XXXX "
              />
            </Form.Group>
          </div>

          <HorizontalRule />
          <div className="d-flex justify-content-between px-3">
            <div>
              <Button variant="primary" className="me-5">
                Pension Cost <ChevronRight className="icon-gap-left" />
              </Button>

              <Button variant="primary">
                <FileMinus className="me-3 icon-gap-right" /> Pension policy (PDF)
              </Button>
            </div>

            <Button variant="primary">
              <Save className="me-3 icon-gap-right" />
              Save Changes
            </Button>
          </div>
        </Form>
      </CardComponent>
    </div>
  );
}

export default GeneralInfo;
