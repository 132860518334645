import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { EmployeePensionCostDataType } from '../../../types/base/TableTypes';

type Props = {
  values: EmployeePensionCostDataType['data'];
};

function TableEmployeePensionStatement({ values }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [OpenIndex, setOpenIndex] = useState<number | null>(null);
  return (
    <React.Fragment>
      {values.data.map((item, i) => (
        <React.Fragment key={i}>
          <tr
            onClick={() => {
              if (OpenIndex === i) {
                setOpenIndex(null);
                setIsOpen(false);
              } else {
                setOpenIndex(i);
                setIsOpen(true);
              }
            }}
          >
            <td>{item['pension company']}</td>
            <td>{item.apr}</td>
            <td>{item.may}</td>
            <td>{item.jun}</td>
            <td>{item.july}</td>
            <td>{item.aug}</td>
            <td>{item.sep}</td>
            <td className="cursor-pointer">{OpenIndex === i ? <ChevronDown /> : <ChevronUp />}</td>
          </tr>
          <div>
            <Collapse key={i} in={isOpen && OpenIndex === i}>
              <div>Additional Data here (TBD)</div>
            </Collapse>
          </div>
        </React.Fragment>
      ))}
      {values.total.map((item, i) => (
        <tr key={i} className="border border-primary shadow p-3 mb-5 bg-body rounded">
          <th className="text-dark">Total</th>
          <td>{item.apr}</td>
          <td>{item.may}</td>
          <td>{item.jun}</td>
          <td>{item.july}</td>
          <td>{item.aug}</td>
          <td>{item.sep}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default TableEmployeePensionStatement;
