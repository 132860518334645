import React from 'react';
import { EmployeeOverviewPieChartType } from '../../../types/base/TableTypes';

type Props = {
  values: EmployeeOverviewPieChartType['data'];
};

function TableEmployeeOverviewPie({ values }: Props) {
  return (
    <React.Fragment>
      {values.map((item, i) => (
        <tr key={i}>
          <td>{item.source}</td>
          <td>{item.revenue}</td>
          <td>{item.value}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default TableEmployeeOverviewPie;
