import { ADMIN_COMPANIES_DETAIL_ENDPOINT, API_VERSION, INITIALIZE_AUTH, INVOKE_TOKEN } from '../../../util/constants';

import { post, get } from '../../../util/httpService';
import { InitilizeSessionType, InvokeSessionType } from './types';

const initializeSession = async (method: string) => {
  const endpoint = `${API_VERSION}${INITIALIZE_AUTH}`;
  const session: InitilizeSessionType = await post(
    endpoint,
    {
      signinMethod: method,
    },
    {},
  );

  return session;
};

const invokeToken = async (sessionId: string) => {
  const endpoint = `${API_VERSION}${INVOKE_TOKEN}`;
  const session: InvokeSessionType = await post(
    endpoint,
    {
      sessionId,
    },
    {},
  );

  return session;
};

const getAdminCompaniesDetail = async (adminPersonalNumber: string) => {
  const endpoint = `${API_VERSION}${ADMIN_COMPANIES_DETAIL_ENDPOINT}/${adminPersonalNumber}`;
  const session = await get(endpoint);

  return session;
};

export { initializeSession, invokeToken, getAdminCompaniesDetail };
