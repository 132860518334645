import React from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import TableComponent from '../../components/table/TableComponent';
import {
  SuperAdminPensionPolicyCompaniesData,
  SuperAdminPensionPolicyCompaniesHeader,
  SuperAdminPensionPolicyCompensationDealData,
  SuperAdminPensionPolicyCompensationDealHeader,
  SuperAdminPensionPolicySalaryData,
  SuperAdminPensionPolicySalaryHeader,
} from '../../dummy/data';
import {
  SuperAdminPensionPolicyCompaniesType,
  SuperAdminPensionPolicyCompensationDealType,
  SuperAdminPensionPolicySalaryType,
} from '../../types/base/TableTypes';

const tabledataCompensationDeal: SuperAdminPensionPolicyCompensationDealType = {
  type: 'SUPER_ADMIN_PENSION_POLICY_COMPENSATION_DEAL',
  data: SuperAdminPensionPolicyCompensationDealData,
};

const tabledataSalary: SuperAdminPensionPolicySalaryType = {
  type: 'SUPER_ADMIN_PENSION_POLICY_SALARY',
  data: SuperAdminPensionPolicySalaryData,
};

const tabledataCompanies: SuperAdminPensionPolicyCompaniesType = {
  type: 'SUPER_ADMIN_PENSION_POLICY_COMPANY',
  data: SuperAdminPensionPolicyCompaniesData,
};

function PensionPoliciesDetailsEditable() {
  return (
    <CardComponent>
      <Form>
        <Row className="mb-3">
          <Col md="6" xl>
            <Form.Group className="mb-3">
              <Form.Label>Policy Name</Form.Label>
              <Form.Control size="sm" type="text" name="policy-name" placeholder="Enter Policy Name" />
            </Form.Group>
          </Col>
          <Col md="6" xl>
            <Form.Group className="mb-3">
              <Form.Label>Version</Form.Label>
              <Form.Control size="sm" type="text" name="version" placeholder="Enter Version" />
            </Form.Group>
          </Col>
          <Col md="6" xl>
            <Form.Group className="mb-3">
              <Form.Label>Employee Type</Form.Label>
              <Form.Control size="sm" type="text" name="employee-type" placeholder="Enter Emp Type" />
            </Form.Group>
          </Col>
          <Col md="6" xl>
            <Form.Group className="mb-3">
              <Form.Label>Effective From</Form.Label>
              <Form.Control size="sm" type="text" name="effective-from" placeholder="Enter Effective From" />
            </Form.Group>
          </Col>
          <Col md="6" xl>
            <Form.Group className="mb-3">
              <Form.Check
                name="roles"
                inline
                type="checkbox"
                id="agreement"
                label="Lock as collective Agreement"
                value={'true'}
              />
            </Form.Group>
          </Col>
        </Row>

        <PagesHeader pageTitle="Policy Settings" />
        <Tabs defaultActiveKey="deal" className="mb-3">
          <Tab eventKey="deal" title="Compensation Deals">
            <TableComponent
              header={SuperAdminPensionPolicyCompensationDealHeader}
              tableItem={tabledataCompensationDeal}
            />
          </Tab>

          <Tab eventKey="types" title="Salary Types">
            <TableComponent header={SuperAdminPensionPolicySalaryHeader} tableItem={tabledataSalary} />
          </Tab>

          <Tab eventKey="companies" title="Pension Companies">
            <TableComponent header={SuperAdminPensionPolicyCompaniesHeader} tableItem={tabledataCompanies} />
          </Tab>
        </Tabs>
      </Form>
    </CardComponent>
  );
}

export default PensionPoliciesDetailsEditable;
