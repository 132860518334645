import useSidebar from '../../hooks/useSidebar';
import SidebarNav from './sidebar_nav/SidebarNav';
import CorrectoLogo from '../../assets/img/c-logo.svg';

import { SidebarItemsType } from '../../types/sidebar';

interface SidebarProps {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  logo: string;
  open?: boolean;
  showFooter?: boolean;
}

const Sidebar = ({ items, logo }: SidebarProps) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        {/* <PerfectScrollbar> */}
        <a className="sidebar-brand" href="/dashboard">
          <img src={logo} alt="logo" />
          {/* <span className="align-middle">
            <Text />
          </span> */}
        </a>

        <SidebarNav items={items} />
        {/* </PerfectScrollbar> */}
        <img className="position-absolute bottom-0 mx-4" src={CorrectoLogo} alt="logo" height={70} width={70} />
      </div>
    </nav>
  );
};

export default Sidebar;
