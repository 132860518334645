import React from 'react';
import { Table } from 'react-bootstrap';
import {
  ITableHeader,
  CompanyOverviewPieChartType,
  CompanyOverviewProjectType,
  CompanyEmployeesDataType,
  CompanyPensionCostDataType,
  CompanyReportsDataType,
  EmployeeOverviewPieChartType,
  EmployeeOverviewProjectType,
  EmployeePensionCostDataType,
  EmployeePensionPaidDataType,
  CompanyClientDataType,
  SuperAdminPensionPoliciesDataType,
  SuperAdminPensionPolicyCompensationDealType,
  SuperAdminPensionPolicySalaryType,
  SuperAdminPensionPolicyCompaniesType,
} from '../../types/base/TableTypes';
import TableCompanyEmployee from './company/CompanyEmployee';
import TableCompanyOverviewPie from './company/CompanyOverviewPie';
import TableCompanyOverviewProject from './company/CompanyOverviewProject';
import TableCompanyPensionCost from './company/CompanyPensionCost';
import TableEmployeeOverviewPie from './employee/EmployeeOverviewPie';
import TableEmployeeOverviewProject from './employee/EmployeeOverviewProject';
import TableEmployeePensionStatement from './employee/EmployeePensionStatement';
import TableEmployeePensionPaid from './employee/EmployeePensionPaid';
import TableCompanyClient from './super_admin/CompanyClient';
import TableHeader from './TableHeader';
import SuperAdminPensionPolicies from './super_admin/SuperAdminPensionPolicies';
import SuperAdminPensionPoliciesCompensation from './super_admin/SuperAdminPensionPoliciesCompensation';
import SuperAdminPensionPoliciesSalary from './super_admin/SuperAdminPensionPoliciesSalary';
import SuperAdminPensionPoliciesCompany from './super_admin/SuperAdminPensionPoliciesCompany';
// import TableValues from './TableValues';

type Props<T> = {
  header: ITableHeader[];
  tableItem: T;
};

type TableTypes =
  | CompanyOverviewPieChartType
  | CompanyOverviewProjectType
  | CompanyEmployeesDataType
  | CompanyPensionCostDataType
  | CompanyReportsDataType
  | EmployeeOverviewPieChartType
  | EmployeeOverviewProjectType
  | EmployeePensionCostDataType
  | EmployeePensionPaidDataType
  | CompanyClientDataType
  | SuperAdminPensionPoliciesDataType
  | SuperAdminPensionPolicyCompensationDealType
  | SuperAdminPensionPolicySalaryType
  | SuperAdminPensionPolicyCompaniesType;

export default function TableComponent({ header, tableItem }: Props<TableTypes>) {
  const Component = () => {
    switch (tableItem.type) {
      case 'COMPANY_OVERVIEW_PIECHART':
        return <TableCompanyOverviewPie values={tableItem.data} />;
      case 'COMPANY_OVERVIEW_PROJECT':
        return <TableCompanyOverviewProject values={tableItem.data} />;
      case 'COMPANY_EMPLOYEE':
        return <TableCompanyEmployee values={tableItem.data} />;
      case 'COMPANY_PENSION_COST':
        return <TableCompanyPensionCost values={tableItem.data} />;
      case 'COMPANY_REPORTS':
        return <TableCompanyEmployee values={tableItem.data} />;
      case 'EMPLOYEE_OVERVIEW_PIECHART':
        return <TableEmployeeOverviewPie values={tableItem.data} />;
      case 'EMPLOYEE_OVERVIEW_PROJECT':
        return <TableEmployeeOverviewProject values={tableItem.data} />;
      case 'EMPLOYEE_PENSION_COST':
        return <TableEmployeePensionStatement values={tableItem.data} />;
      case 'EMPLOYEE_PENSION_PAID':
        return <TableEmployeePensionPaid values={tableItem.data} />;
      case 'SUPER_ADMIN_COMPANY_CLIENT':
        return <TableCompanyClient values={tableItem.data} />;
      case 'SUPER_ADMIN_PENSION_POLICY':
        return <SuperAdminPensionPolicies values={tableItem.data} />;
      case 'SUPER_ADMIN_PENSION_POLICY_COMPENSATION_DEAL':
        return <SuperAdminPensionPoliciesCompensation values={tableItem.data} />;
      case 'SUPER_ADMIN_PENSION_POLICY_SALARY':
        return <SuperAdminPensionPoliciesSalary values={tableItem.data} />;
      case 'SUPER_ADMIN_PENSION_POLICY_COMPANY':
        return <SuperAdminPensionPoliciesCompany values={tableItem.data} />;
      default:
        <p>Not found</p>;
    }
  };

  return (
    <React.Fragment>
      <Table className="mb-0" responsive>
        <TableHeader header={header} />
        <tbody>{Component()}</tbody>
      </Table>
    </React.Fragment>
  );
}
