import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CardComponent from '../components/base/CardComponent';
import HorizontalRule from '../components/base/HorizontalRule';
import PagesHeader from '../components/base/PagesHeader';
import GeneralInfo from '../components/form/GeneralInfo';
import ReportHeader from '../components/report/ReportHeader';
import ReportLine from '../components/report/ReportLine';

function PensionTab() {
  return (
    <div>
      <GeneralInfo />
      <div className="mb-3">
        <Row>
          <PagesHeader pageTitle="Pension offset " />
          <Col md="6" xl>
            <CardComponent>
              <ReportHeader btn={{ text: 'Prev Month' }} title="November" />
              <div className="mt-3">
                <ReportLine label="Salary (Code 12)" value="570000" tooltip="salary tooltip" type="LABEL_VALUE" />
                <ReportLine label="Sickleave" value="-20000" type="LABEL_VALUE" />
                <ReportLine label="Holidays" value="-4000" type="LABEL_VALUE" />
                <ReportLine label="VAB = care of [sick] child" value="-2000" type="LABEL_VALUE" />
              </div>
              <HorizontalRule />
              <div className="mt-3">
                <ReportLine
                  label="Totalt Pension Offset Salary"
                  value="50432"
                  tooltip="salary tooltip"
                  type="LABEL_VALUE"
                />
                <ReportLine label="Pension Offset" value="4260" type="LABEL_VALUE" />
                <ReportLine label="Salary change (Salary to Pension)" value="0" type="LABEL_VALUE" />
              </div>
              <HorizontalRule />
              <ReportLine
                label="Correction pension provision"
                value="-591"
                tooltip="salary tooltip"
                type="LABEL_VALUE"
              />
            </CardComponent>
          </Col>
          <Col md="6" xl>
            <CardComponent>
              <ReportHeader btn={{ text: 'Next Month' }} title="December" />
              <div className="mt-3">
                <ReportLine label="Salary (Code 12)" value="570000" tooltip="salary tooltip" type="LABEL_VALUE" />
                <ReportLine label="Sickleave" value="-20000" type="LABEL_VALUE" />
                <ReportLine label="Holidays" value="-4000" type="LABEL_VALUE" />
              </div>
              <HorizontalRule />
              <div className="mt-3">
                <ReportLine
                  label="Totalt Pension Offset Salary"
                  value="50432"
                  tooltip="salary tooltip"
                  type="LABEL_VALUE"
                />
                <ReportLine label="Pension Offset" value="4260" type="LABEL_VALUE" />
                <ReportLine label="Salary change (Salary to Pension)" value="0" type="LABEL_VALUE" />
              </div>
              <HorizontalRule />
              <ReportLine
                label="Correction pension provision"
                value="2190"
                tooltip="salary tooltip"
                type="LABEL_VALUE"
              />
            </CardComponent>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PensionTab;
