import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import TableFilter from '../../components/table/TableFilter';
import PagesHeader from '../../components/base/PagesHeader';
import { IBreadCrumb } from '../../types/base/BreadCrumb';
import { FileMinus, Printer } from 'react-feather';
import HorizontalRule from '../../components/base/HorizontalRule';
import TableComponent from '../../components/table/TableComponent';
import { CompanyReportsDataType } from '../../types/base/TableTypes';
import { CompanyReportData, CompanyReportHeader } from '../../dummy/data';

const tabledata: CompanyReportsDataType = {
  type: 'COMPANY_REPORTS',
  data: CompanyReportData,
};

function ReportDetails() {
  const { name } = useParams<{ name: string }>();
  const [breadcrumb] = useState<IBreadCrumb[]>([
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Reports',
      href: '/reports',
    },
    {
      name: `${name}`,
    },
  ]);

  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle={`${name}`} />
      <CardComponent>
        <Row>
          <Form>
            <div className="d-flex mb-3 align-items-center">
              <Form.Group className="me-3 w-25">
                <Form.Label>From date </Form.Label>
                <Form.Control type="date" placeholder="Enter Start Date" name="Start Date" value="2018-07-22" />
              </Form.Group>

              <Form.Group className="me-3 w-25">
                <Form.Label>To date </Form.Label>
                <Form.Control type="date" placeholder="Enter To End" name="To End" value="2018-07-22" />
              </Form.Group>

              <TableFilter label="Cost center" items={['Marketing', 'Some', 'None']} />

              <Form.Group className="me-3 ">
                <Form.Label>Salary code</Form.Label>
                <Form.Control placeholder="Enter Salary Code" name="Salary Code" value="" />
              </Form.Group>

              <TableFilter label="Employee" items={['All', 'Some', 'None']} />
            </div>
          </Form>
        </Row>
        <HorizontalRule />
        <Row>
          <div className="d-flex justify-content-between">
            <p className="h3">{name}</p>
            <div className="d-flex gap-3">
              <Button variant="primary">
                <FileMinus className="icon-gap-right" />
                Export Pdf
              </Button>
              <Button variant="primary">
                <Printer className="icon-gap-right" />
                Print Report
              </Button>
            </div>
          </div>
        </Row>

        <Row className="mt-3">
          <TableComponent header={CompanyReportHeader} tableItem={tabledata} />
        </Row>
      </CardComponent>
    </div>
  );
}

export default ReportDetails;
