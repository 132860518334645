import React from 'react';
import { useNavigate } from 'react-router-dom';

import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { AuthUser, AuthState, ActionMap, ContextType } from '../types/auth';
import useLocalStorage from '../hooks/useLocalStorage';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
    accessToken: string;
  };
  [SIGN_OUT]: null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthReducer = (state: AuthState, action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isLoading: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        isLoading: false,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<ContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userLocalStorage, _, removeUserLocalStorage] = useLocalStorage('user', null);
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        if (userLocalStorage) {
          signIn(userLocalStorage);
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (user: AuthUser) => {
    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated: true,
        user: user,
      },
    });
    navigate('/dashboard');
  };

  const signOut = async () => {
    removeUserLocalStorage('user');
    dispatch({
      type: SIGN_OUT,
      payload: null,
    });
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
