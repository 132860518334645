import { ITableHeader } from '../types/base/TableTypes';

// Company Admin Dummy Data

export const CompanyOverviewPieChartHeader: ITableHeader[] = [
  {
    label: 'Source',
  },
  {
    label: 'Revenue',
  },
  {
    label: 'Value',
  },
];

export const CompanyOverviewPieChartData = [
  {
    source: 'Direct',
    revenue: '$ 2602',
    value: '+43%',
  },
  {
    source: 'Direct',
    revenue: '$ 2602',
    value: '+43%',
  },
  {
    source: 'Direct',
    revenue: '$ 2602',
    value: '+43%',
  },
];

export const CompanyOveriewProjectsHeader: ITableHeader[] = [
  {
    label: 'Name',
  },
  {
    label: 'Start Date',
  },
  {
    label: 'End Date',
  },
  {
    label: 'Status',
  },
  {
    label: 'Assignee',
  },
];

export const CompanyOverviewProjectData = [
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
];

export const CompanyEmployeesHeader: ITableHeader[] = [
  {
    label: 'Name',
  },
  {
    label: 'Personal no',
  },
  {
    label: 'Date of employment',
  },
  {
    label: 'Salary',
  },
  {
    label: 'Agreement',
  },
  {
    label: 'Pension cost',
  },
];

export const CompanyEmployeesData = [
  {
    name: 'Armando Reinger',
    personalNumber: '98206826-2563',
    employeeCompany: {
      company: {
        name: 'Connelly and Sons',
      },
      employmentStartDate: '2022-09-27',
    },
    agreement: {
      name: 'reinvent plug-and-play markets',
    },
    currentMonthlySalary: {
      amount: 66.71,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 4.51,
      pensionSalaryAmount: 65.3,
    },
  },
  {
    name: 'Maxine Vandervort',
    personalNumber: '63791574-1483',
    employeeCompany: {
      company: {
        name: 'Metz, Dooley and Bode',
      },
      employmentStartDate: '2022-08-31',
    },
    agreement: {
      name: 'streamline strategic solutions',
    },
    currentMonthlySalary: {
      amount: 60.18,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 0.53,
      pensionSalaryAmount: 55.52,
    },
  },
  {
    name: 'Miss Edmund Gottlieb',
    personalNumber: '45197794-7394',
    employeeCompany: {
      company: {
        name: 'Conn - Waelchi',
      },
      employmentStartDate: '2021-12-13',
    },
    agreement: {
      name: 'envisioneer one-to-one platforms',
    },
    currentMonthlySalary: {
      amount: 67.59,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 0.7,
      pensionSalaryAmount: 59.26,
    },
  },
  {
    name: 'Mandy Zemlak',
    personalNumber: '72213178-5968',
    employeeCompany: {
      company: {
        name: 'Jacobson and Sons',
      },
      employmentStartDate: '2022-09-04',
    },
    agreement: {
      name: 'streamline 24/7 e-business',
    },
    currentMonthlySalary: {
      amount: 58.05,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 1.45,
      pensionSalaryAmount: 56.97,
    },
  },
  {
    name: 'Tami Mayer',
    personalNumber: '82246061-2467',
    employeeCompany: {
      company: {
        name: 'Orn, Brakus and Grimes',
      },
      employmentStartDate: '2022-08-02',
    },
    agreement: {
      name: 'extend viral action-items',
    },
    currentMonthlySalary: {
      amount: 55.35,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 3.7,
      pensionSalaryAmount: 59.37,
    },
  },
  {
    name: 'Bryan Osinski DDS',
    personalNumber: '50989349-2051',
    employeeCompany: {
      company: {
        name: 'Douglas - Stanton',
      },
      employmentStartDate: '2022-08-27',
    },
    agreement: {
      name: 'implement world-class e-commerce',
    },
    currentMonthlySalary: {
      amount: 53.37,
      date: '2022-11-08',
    },
    currentMonthlyPension: {
      pensionAmount: 3.1,
      pensionSalaryAmount: 66.81,
    },
  },
  {
    name: 'Guadalupe Parker',
    personalNumber: '84384628-2030',
    employeeCompany: {
      company: {
        name: 'Considine, Senger and Gutkowski',
      },
      employmentStartDate: '2022-07-12',
    },
    agreement: {
      name: 'iterate ubiquitous experiences',
    },
    currentMonthlySalary: {
      amount: 51.77,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 0.03,
      pensionSalaryAmount: 68.13,
    },
  },
  {
    name: 'Bridget Mohr',
    personalNumber: '34248414-3561',
    employeeCompany: {
      company: {
        name: 'Carroll, Adams and Rodriguez',
      },
      employmentStartDate: '2022-10-25',
    },
    agreement: {
      name: 'cultivate end-to-end eyeballs',
    },
    currentMonthlySalary: {
      amount: 69.39,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 0.47,
      pensionSalaryAmount: 57.26,
    },
  },
  {
    name: 'Benjamin Pollich',
    personalNumber: '29351627-3420',
    employeeCompany: {
      company: {
        name: 'Wisoky, Ortiz and Wisoky',
      },
      employmentStartDate: '2021-12-12',
    },
    agreement: {
      name: 'incentivize distributed metrics',
    },
    currentMonthlySalary: {
      amount: 54.7,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 4.91,
      pensionSalaryAmount: 68.71,
    },
  },
  {
    name: 'Horace Stehr',
    personalNumber: '59174467-5933',
    employeeCompany: {
      company: {
        name: 'Feil Group',
      },
      employmentStartDate: '2022-05-21',
    },
    agreement: {
      name: 'evolve dot-com users',
    },
    currentMonthlySalary: {
      amount: 63.53,
      date: '2022-11-09',
    },
    currentMonthlyPension: {
      pensionAmount: 0.02,
      pensionSalaryAmount: 57.84,
    },
  },
];

export const CompanyPensionCostHeader: ITableHeader[] = [
  {
    label: 'Pension Company',
  },
  {
    label: 'Apr',
  },
  {
    label: 'May',
  },
  {
    label: 'Jun',
  },
  {
    label: 'July',
  },
  {
    label: 'Aug',
  },
  {
    label: 'Sep',
  },
];

export const CompanyPensionCostData = {
  data: [
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
  ],
  total: [
    {
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
  ],
};

export const CompanyReportHeader: ITableHeader[] = CompanyEmployeesHeader;

export const CompanyReportData = CompanyEmployeesData;

// Employee Dummy Data

export const EmployeeOverviewPieChartHeader: ITableHeader[] = [
  {
    label: 'Source',
  },
  {
    label: 'Revenue',
  },
  {
    label: 'Value',
  },
];

export const EmployeeOverviewPieChartData = [
  {
    source: 'Direct',
    revenue: '$ 2602',
    value: '+43%',
  },
  {
    source: 'Direct',
    revenue: '$ 2602',
    value: '+43%',
  },
  {
    source: 'Direct',
    revenue: '$ 2602',
    value: '+43%',
  },
];

export const EmployeeOveriewProjectsHeader: ITableHeader[] = [
  {
    label: 'Name',
  },
  {
    label: 'Start Date',
  },
  {
    label: 'End Date',
  },
  {
    label: 'Status',
  },
  {
    label: 'Assignee',
  },
];

export const EmployeeOverviewProjectData = [
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
  {
    name: 'Project Apollo',
    'start Date': '01/01/2021',
    'end Date': '31/06/2021',
    status: 'Done',
    assignee: 'Carl Jenkins',
  },
];

export const EmployeePensionCostHeader: ITableHeader[] = [
  {
    label: 'Pension Company',
  },
  {
    label: 'Apr',
  },
  {
    label: 'May',
  },
  {
    label: 'Jun',
  },
  {
    label: 'July',
  },
  {
    label: 'Aug',
  },
  {
    label: 'Sep',
  },
];

export const EmployeePensionCostData = {
  data: [
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
  ],
  total: [
    {
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
  ],
};

export const EmployeePensionPaidHeader: ITableHeader[] = [
  {
    label: 'Pension Company',
  },
  {
    label: 'Apr',
  },
  {
    label: 'May',
  },
  {
    label: 'Jun',
  },
  {
    label: 'July',
  },
  {
    label: 'Aug',
  },
  {
    label: 'Sep',
  },
];

export const EmployeePensionPaidData = {
  data: [
    {
      'pension company': 'Avanza Future',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: '1234',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: '28492',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
    {
      'pension company': 'Avanza',
      apr: '9420',
      may: '100',
      jun: '6770',
      july: '2460',
      aug: '3360',
      sep: 'Sep',
    },
  ],
  summary: [
    {
      apr: '9420',
      may: '100',
      jun: '-',
      july: '2460',
      aug: '3360',
      sep: '2302',
    },
  ],
};

// Super Admin Data

export const CompanyClientsHeader: ITableHeader[] = [
  {
    label: 'Company Name',
  },
  {
    label: 'Org. number',
  },
  {
    label: 'Employees',
  },
  {
    label: 'Contract number',
  },
  {
    label: 'Status',
  },
  {
    label: 'Creation date',
  },
  {
    label: 'Last edit',
  },
];

export const SuperAdminPensionPolicyHeader: ITableHeader[] = [
  {
    label: 'Name',
  },
  {
    label: 'Version',
  },
  {
    label: 'Employee Type',
  },
  {
    label: 'Effective From',
  },
  {
    label: 'Status',
  },
  {
    label: 'Actions',
  },
  // {
  //   label: 'Edit',
  // },
  // {
  //   label: 'Copy',
  // },
  // {
  //   label: 'Removes',
  // },
];

export const SuperAdminPensionPolicyData = {
  data: [
    {
      name: 'PP01',
      version: '1.0',
      employeeType: 'White Collar',
      effectiveFrom: '20301100',
      status: 'Active',
      edit: false,
    },
    {
      name: 'PP01',
      version: '1.0',
      employeeType: 'White Collar',
      effectiveFrom: '20301100',
      status: 'Inactive',
      edit: false,
    },
    {
      name: 'Alpha Omega',
      version: '1.0',
      employeeType: 'White Collar',
      effectiveFrom: '20301100',
      status: 'Draft',
      edit: true,
    },
    {
      name: 'Alpha Beta',
      version: '1.0',
      employeeType: 'White Collar',
      effectiveFrom: '20301100',
      status: 'Active',
      edit: true,
    },
  ],
};

export const SuperAdminPensionPolicyCompensationDealHeader: ITableHeader[] = [
  {
    label: 'Deal Name',
  },
  {
    label: 'Percentage Compensation',
  },
  {
    label: 'Fix Compensation (SEK)',
  },
  {
    label: 'Salary Interval',
  },
  {
    label: 'Deal Info',
  },
];

export const SuperAdminPensionPolicyCompensationDealData = {
  data: [
    {
      dealName: 'Deal 1',
      percComp: 5,
      fixComp: 0,
      salaryInt: '>44735',
      dealInfo: 'Lorum Ipsum Dolor sit amit',
    },
    {
      dealName: ' ',
      percComp: 5,
      fixComp: 0,
      salaryInt: '>44735',
      dealInfo: 'Lorum Ipsum Dolor sit amit',
    },
    {
      dealName: 'Deal 3',
      percComp: 0,
      fixComp: 0,
      salaryInt: '0',
      dealInfo: 'Lorum Ipsum Dolor sit amit',
    },
  ],
};

export const SuperAdminPensionPolicySalaryHeader: ITableHeader[] = [
  {
    label: 'Salary Code',
  },
  {
    label: 'Name',
  },
  {
    label: 'Salary Type',
  },
  {
    label: 'Unit',
  },
];

export const SuperAdminPensionPolicySalaryData = {
  data: [
    { salaryCode: 12, name: 'Ajanta Mandis', salaryType: 'Bruttoe', unit: 'Manad' },
    { salaryCode: 1322, name: 'Provision, enganggskatt', salaryType: 'Bruttoe' },
  ],
};

export const SuperAdminPensionPolicyCompaniesHeader: ITableHeader[] = [
  {
    label: 'Pension Companies',
  },
];

export const SuperAdminPensionPolicyCompaniesData = {
  data: [
    { company: 'Handelsbanken Liv' },
    { company: 'Handelsbanken Liv' },
    { company: 'Handelsbanken Liv' },
    { company: 'Handelsbanken Liv' },
  ],
};

export const PersonalClientsHeader: ITableHeader[] = [
  {
    label: 'Name',
  },
  {
    label: 'Personal number',
  },
  {
    label: 'Contract number',
  },
  {
    label: 'Status',
  },
  {
    label: 'Creation date',
  },
  {
    label: 'Last edit',
  },
];
