import React, { useState, useEffect } from 'react';
import { Col, Row, Container, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { Plus } from 'react-feather';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';

import { IBreadCrumb } from '../../types/base/BreadCrumb';

import { CompanyClientsHeader } from '../../dummy/data';
import { CompanyClientDataType } from '../../types/base/TableTypes';

import TableComponent from '../../components/table/TableComponent';
import ModalsHeader from '../../components/base/ModalsHeader';
import { CompanyClient, GetCompanyClientType } from '../../api/service/superAdmin/company-clients/types';
import {
  getCompanyClients,
  postNewCompanyClient,
  getMockCompanyName,
  getMockUserName,
} from '../../api/service/superAdmin/company-clients/service';

const STRING_TYPE = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
  },
  {
    name: 'Company Clients',
  },
];

const colors = [
  {
    name: 'New company client',
    value: 'primary',
  },
];

// const AdminUserList = (props: { index: number; handleRemoveAdmin: () => void }) => {
//   return (
//     <Row className="mb-3">
//       <Col md="4">
//         <Form.Group>
//           <Form.Label>Personal number</Form.Label>
//           <div className="d-flex gap-3">
//             <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" />
//             <Button variant="primary" className="d-flex align-items-center w-100">
//               <Plus /> Fetch name
//             </Button>
//           </div>
//         </Form.Group>
//       </Col>
//       <Col md="3">
//         <Form.Group>
//           <Form.Label>Name</Form.Label>
//           <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" disabled />
//         </Form.Group>
//       </Col>
//       <Col md="3">
//         <Form.Group>
//           <Form.Label>Email address</Form.Label>
//           <Form.Control size="lg" type="text" name="policy-name" placeholder="Enter Policy Name" disabled />
//         </Form.Group>
//       </Col>
//       <Col md="2" className="d-flex align-items-end justify-content-end">
//         <Button onClick={() => handleRemoveAdmin(index)} className="d-flex align-items-center ">
//           <Delete />
//         </Button>
//       </Col>
//     </Row>
//   );
// };

const CenteredModal = () => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState<any>(() => initOpenModals());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCompanyCreated, setIsCompanyCreated] = useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = useState<Record<string, string> | null>(null);
  const [adminDetails, setAdminDetails] = useState<Record<string, string> | null>(null);
  const [addressDetails, setAddressDetails] = useState<Record<string, string> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [adminList, setAdminList] = useState([{}]);

  const toggle = (index: any) => {
    setOpenModals((openModals: { [x: string]: any }) => Object.assign({}, openModals, { [index]: !openModals[index] }));
  };

  // const handleAddAdmin = () => {
  //   setAdminList([...adminList, {}]);
  // };

  // const handleRemoveAdmin = (index: number) => {
  //   // remove index from admin list
  //   adminList.splice(index, 1);
  // };

  const handleSetCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyDetails((value) => ({
      ...value,
      [e['target']['name']]: e['target']['value'],
    }));
  };
  const handleAdminCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminDetails((value) => ({
      ...value,
      [e['target']['name']]: e['target']['value'],
    }));
  };
  const handleAddressCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressDetails((value) => ({
      ...value,
      [e['target']['name']]: e['target']['value'],
    }));
  };

  const handleSaveCompany = async (type: string, index: any) => {
    setIsCompanyCreated(false);
    setIsLoading(true);
    const data = {
      ...companyDetails,
      address: { ...addressDetails },
      admins: [{ ...adminDetails }],
    } as CompanyClient;

    let params = {};

    if (type === STRING_TYPE.DRAFT) {
      params = {
        draft: true,
        sendEmails: false,
      };
    } else {
      params = {
        draft: false,
        sendEmails: true,
      };
    }

    try {
      await postNewCompanyClient(data, params);
      setIsCompanyCreated(true);
      setCompanyDetails(null);
      setAddressDetails(null);
      setAdminDetails(null);
      toggle(index);
    } catch (error) {
      if (error instanceof Error) console.error(error.message);
      setIsCompanyCreated(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button variant={color.value} onClick={() => toggle(index)} className="me-1">
            <Plus className="icon-gap-right" />
            {color.name}
          </Button>
          <Modal
            show={openModals[index]}
            onHide={() => {
              setCompanyDetails(null);
              setAddressDetails(null);
              setAdminDetails(null);
              toggle(index);
            }}
            centered
            className="modal-box"
          >
            <Modal.Header closeButton>
              <ModalsHeader pageTitle="Company Clients" />
            </Modal.Header>
            <Form>
              <Modal.Body className="m-2">
                <div className="form-section mb-3">
                  <h4 className="mb-2">Company details</h4>
                  <Row className="mb-3">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Organization number (10 digits)</Form.Label>
                        <div className="d-flex gap-3">
                          <Form.Control
                            size="lg"
                            type="text"
                            onChange={handleSetCompany}
                            name="organizationNumber"
                            required
                            placeholder="Enter Number"
                          />
                          <Button
                            variant="primary"
                            onClick={async (e) => {
                              const companyName = await getMockCompanyName();
                              setCompanyDetails((values) => ({
                                ...values,
                                companyName,
                              }));
                            }}
                            className="d-flex align-items-center w-100"
                          >
                            <Plus /> Fetch name
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Company name</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleSetCompany}
                          name="companyName"
                          value={companyDetails && companyDetails['companyName'] ? companyDetails['companyName'] : ''}
                          disabled
                          required
                          placeholder="Enter Name"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleAddressCompany}
                          name="street"
                          placeholder="street"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Postcode </Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleAddressCompany}
                          name="postcode"
                          required
                          placeholder="postcode"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleAddressCompany}
                          name="city"
                          required
                          placeholder="city"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleAddressCompany}
                          name="country"
                          required
                          placeholder="country"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="form-section mb-3">
                  <h4 className="mb-2">Authorized signatory</h4>
                  <Row className="mb-3">
                    <Col md="5">
                      <Form.Group>
                        <Form.Label>Personal number (10 digits)</Form.Label>
                        <div className="d-flex gap-3">
                          <Form.Control
                            size="lg"
                            type="text"
                            onChange={handleAdminCompany}
                            name="personalNumber"
                            required
                            placeholder="Enter Number"
                          />
                          <Button
                            variant="primary"
                            onClick={async () => {
                              const fullName = await getMockUserName();
                              setAdminDetails((values) => ({
                                ...values,
                                fullName,
                              }));
                            }}
                            className="d-flex align-items-center w-100"
                          >
                            <Plus /> Fetch name
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleAdminCompany}
                          name="fullName"
                          value={adminDetails && adminDetails['fullName'] ? adminDetails['fullName'] : ''}
                          disabled
                          required
                          placeholder="Enter Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          onChange={handleAdminCompany}
                          name="email"
                          required
                          placeholder="Enter Email"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                {/* <div className="form-section mb-3">
                  <h4 className="mb-2">Admin details</h4>
                  {adminList.map((item, i) => (
                    <AdminUserList key={i} handleRemoveAdmin={handleRemoveAdmin} index={i} />
                  ))}
                  <Row className="mb-3">
                    <Col className="d-flex ">
                      <Button className="d-flex align-items-center" onClick={handleAddAdmin}>
                        <Plus />
                      </Button>
                    </Col>
                  </Row>
                </div> */}
              </Modal.Body>
              {isLoading ? (
                <Modal.Footer className="d-flex justify-content-center">
                  <div className="mt-4 ">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </Modal.Footer>
              ) : (
                <Modal.Footer>
                  <div className="d-flex justify-content-between w-100">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setCompanyDetails(null);
                        setAddressDetails(null);
                        setAdminDetails(null);
                        toggle(index);
                      }}
                    >
                      <Plus />
                      Cancel
                    </Button>

                    <div className="d-flex gap-3">
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                          handleSaveCompany(STRING_TYPE.DRAFT, index);
                        }}
                      >
                        <Plus />
                        Save as draft
                      </Button>
                      <Button
                        variant={color.value}
                        type="button"
                        onClick={() => handleSaveCompany(STRING_TYPE.SUBMIT, index)}
                      >
                        <Plus />
                        Save company client and send all invites
                      </Button>
                    </div>
                  </div>
                </Modal.Footer>
              )}
            </Form>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

function CompanyClientsPage() {
  const [companyData, setCompanyData] = useState<GetCompanyClientType | null>(null);

  const tabledata: CompanyClientDataType = {
    type: 'SUPER_ADMIN_COMPANY_CLIENT',
    data: companyData,
  };

  useEffect(() => {
    const apiCall = async () => {
      const user = await getCompanyClients();
      setCompanyData(user);
    };

    apiCall();
  }, []);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader pageTitle="Company Clients" />
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Link to="/company-clients">
            <CenteredModal />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex">
          <CardComponent>
            <TableComponent header={CompanyClientsHeader} tableItem={tabledata} />
          </CardComponent>
        </Col>
      </Row>
    </Container>
  );
}

export default CompanyClientsPage;
