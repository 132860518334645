import React from 'react';
import { SuperAdminPensionPolicyCompaniesType } from '../../../types/base/TableTypes';

type Props = {
  values: SuperAdminPensionPolicyCompaniesType['data'];
};

function SuperAdminPensionPoliciesCompany({ values }: Props) {
  return (
    <React.Fragment>
      {values.data.map((item, i) => (
        <tr key={i}>
          <td>{item['company']}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default SuperAdminPensionPoliciesCompany;
