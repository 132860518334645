import React from 'react';
import { useNavigate } from 'react-router-dom';
import HorizontalRule from '../base/HorizontalRule';

type Props = {
  title: string;
  link?: boolean;
};

function ReportTitle({ title, link }: Props) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/reports/${title}`);
  };

  return (
    <React.Fragment>
      <span
        className={`fw-bold fs-3 mb-1 ${link ? 'cursor-pointer text-primary' : 'text-dark'} `}
        onClick={link ? handleNavigate : () => {}}
      >
        {title}{' '}
      </span>
      <HorizontalRule />
    </React.Fragment>
  );
}

export default ReportTitle;
