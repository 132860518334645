import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import 'chart.js/auto';
import './App.css';

import { routesCompany, routesEmployee, routesSuperAdmin } from './routes';

import { ThemeProvider } from './contexts/ThemeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { LayoutProvider } from './contexts/LayoutContext';
import useAuth from './hooks/useAuth';
import { UserRole } from './types/auth';

function App() {
  const { user } = useAuth();
  const content = useRoutes(
    user?.role === UserRole.EMPLOYEE
      ? routesEmployee
      : user?.role === UserRole.COMPANY_ADMIN
      ? routesCompany
      : routesSuperAdmin,
  );

  ChartJS.register(...registerables);
  return (
    <ThemeProvider>
      <SidebarProvider>
        <LayoutProvider>
          {/* <AuthProvider> */}
          {content}
          {/* </AuthProvider> */}
        </LayoutProvider>
      </SidebarProvider>
    </ThemeProvider>
  );
}

export default App;
