import React from 'react';
import { Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-feather';

type Props = {
  btn: {
    text: string;
    disabled?: boolean;
  };
  title: string;
};

function ReportHeader({ btn, title }: Props) {
  return (
    <div className="text-end d-flex justify-content-between">
      <h3>{title}</h3>
      <Button variant="primary" disabled={btn.disabled}>
        {btn.text.toLowerCase().includes('prev') ? (
          <>
            <ChevronLeft className="icon-gap-right" /> {btn.text}
          </>
        ) : (
          <>
            {btn.text} <ChevronRight className="icon-gap-left" />
          </>
        )}
      </Button>
    </div>
  );
}

export default ReportHeader;
