export const API_VERSION = '/api/v1';
export const SESSION_REDIRECT_PATH = '/?initsession=true';

// auth
export const INITIALIZE_AUTH = `/auth/initialize-session?redirectTo=${process.env['REACT_APP_SESSION_REDIRECT_URL']}${SESSION_REDIRECT_PATH}`;
export const INVOKE_TOKEN = '/auth/invoke-token';

// Company endpoints
export const COMPANY_EMPLOYEE_ENDPOINT = '/employee';
export const ADMIN_COMPANIES_DETAIL_ENDPOINT = '/company-clients/admins';

// Employee endpoints

// SuperAdmin endpoints
export const COMPANY_CLIENTS_ENDPOINT = '/company-clients';
export const MOCK_COMPANY_NAME_ENDPOINT = '/company-clients/mock';
export const MOCK_USER_ENDPOINT = '/company-clients/mock/user';

// Company Client endpoints

export const COMPANY_CLIENT_ENDPOINT = '/client';
