import {
  API_VERSION,
  COMPANY_CLIENTS_ENDPOINT,
  MOCK_COMPANY_NAME_ENDPOINT,
  MOCK_USER_ENDPOINT,
} from '../../../../util/constants';

import { get, post } from '../../../../util/httpService';
import { CompanyClient, GetCompanyClientType } from './types';

const getCompanyClients = async () => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}`;
  const company: GetCompanyClientType = await get(endpoint);
  return company;
};

const postNewCompanyClient = async (body: CompanyClient, params: Record<string, boolean>) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}?draft=${params['draft']}&sendEmails=${params['sendEmails']}`;
  const company = await post(endpoint, { ...body }, {});
  return company;
};

const getMockCompanyName = async () => {
  const endpoint = `${API_VERSION}${MOCK_COMPANY_NAME_ENDPOINT}`;

  const { companyName } = await get(endpoint);

  return companyName;
};

const getMockUserName = async () => {
  const endpoint = `${API_VERSION}${MOCK_USER_ENDPOINT}`;

  const { fullName } = await get(endpoint);

  return fullName;
};

export { getCompanyClients, postNewCompanyClient, getMockCompanyName, getMockUserName };
