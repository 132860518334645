import React from 'react';
import { SuperAdminPensionPolicyCompensationDealType } from '../../../types/base/TableTypes';

type Props = {
  values: SuperAdminPensionPolicyCompensationDealType['data'];
};

function SuperAdminPensionPoliciesCompensation({ values }: Props) {
  return (
    <React.Fragment>
      {values.data.map((item, i) => (
        <tr key={i}>
          <td>{item['dealName']}</td>
          <td key={i}>{item['percComp']}</td>
          <td>{item['fixComp']}</td>
          <td>{item['salaryInt']}</td>
          <td>{item['dealInfo']}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default SuperAdminPensionPoliciesCompensation;
