import React from 'react';
import { Bar } from 'react-chartjs-2';

import usePalette from '../../hooks/usePalette';

const BarChart = () => {
  const palette = usePalette();

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Last year',
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      },
      {
        label: 'This year',
        backgroundColor: palette['primary-light'],
        borderColor: palette['primary-light'],
        hoverBackgroundColor: palette['primary-light'],
        hoverBorderColor: palette['primary-light'],
        data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    borderRadius: 15,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
        },
        stacked: true,
      },
      x: {
        grid: {
          color: 'transparent',
        },
        stacked: true,
      },
    },
  };

  return (
    <div className="align-self-center w-100">
      <div className="chart chart-lg">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
