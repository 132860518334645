import React from 'react';

import { Button, Col, Dropdown, Row } from 'react-bootstrap';

import { Calendar, RefreshCw } from 'react-feather';

type Props = {
  pageTitle: string;
  showCalender?: boolean;
  showRefreshBtn?: boolean;
};

const PagesHeader = ({ pageTitle, showCalender = false, showRefreshBtn = false }: Props) => {
  return (
    <Row className="mb-2">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>{pageTitle}</h3>
      </Col>

      <Col xs="auto" className="ms-auto text-end mt-n1">
        {showCalender && (
          <Dropdown className="d-inline me-2">
            <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
              <Calendar className="feather align-middle mt-n1 icon-gap-right" /> Today
            </Dropdown.Toggle>
          </Dropdown>
        )}

        {showRefreshBtn && (
          <Button variant="primary" className="shadow-sm">
            <RefreshCw className="feather" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default PagesHeader;
