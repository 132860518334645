import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import { Icon } from 'react-feather';

type Props = {
  value: string;
  label: string;
  percent: string;
  sinceTime: string;
  Icon: Icon;
};

function Statistics({ Icon, sinceTime, label, percent, value }: Props) {
  return (
    <Card className="flex-fill">
      <Card.Body className=" py-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <h3 className="mb-2">{value}</h3>
            <p className="mb-2">{label}</p>
            <div className="mb-0">
              <Badge bg="" className={`${percent.includes('+') ? 'badge-soft-success' : 'badge-soft-danger'} me-2`}>
                {percent}
              </Badge>
              <span className="text-muted">{sinceTime}</span>
            </div>
          </div>
          <div className="d-inline-block ms-3">
            <div className="stat">
              <Icon className="align-middle text-success" />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default Statistics;
