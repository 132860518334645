import React from 'react';
import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';
import ReportLine from '../../components/report/ReportLine';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Settings',
  },
];

function SettingsPage() {
  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Settings" />
      <CardComponent>
        <PagesHeader pageTitle="Company Details" />
        <div>
          <ReportLine type="LABEL_VALUE" label="Company Name" value="Correcto Technology AB" />
          <ReportLine type="LABEL_VALUE" label="Registration Number" value="L-697v" />
          <ReportLine type="LABEL_VALUE" label="Address" value="2062 Wiseman Street" />
          <ReportLine type="LABEL_VALUE" label="Mail 1" value="2062 Wiseman Street" />
          <ReportLine type="LABEL_VALUE" label="Mail 2" value="2062 Wiseman Street" />
        </div>
      </CardComponent>
    </div>
  );
}

export default SettingsPage;
