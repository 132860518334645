import React from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/base/Breadcrumb';
import CardComponent from '../../components/base/CardComponent';
import PagesHeader from '../../components/base/PagesHeader';

import { IBreadCrumb } from '../../types/base/BreadCrumb';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
  },
];

function OverviewPage() {
  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Overview" />
      <div className="d-flex gap-3 mb-3">
        <Link to="/company-clients">
          <Button variant="primary">
            <ChevronRight className="icon-gap-right" />
            Manage company clients
          </Button>
        </Link>
        <Link to="/personal-clients">
          <Button variant="primary">
            <ChevronRight className="icon-gap-right" /> Manage personal clients
          </Button>
        </Link>
      </div>
      <Row>
        <Col md="4">
          <CardComponent>Sample</CardComponent>
        </Col>
        <Col md="4">
          <CardComponent>Sample</CardComponent>
        </Col>
        <Col md="4">
          <CardComponent>Sample</CardComponent>
        </Col>
      </Row>
    </Container>
  );
}

export default OverviewPage;
