import React, { useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/base/Breadcrumb';
import PagesHeader from '../../components/base/PagesHeader';
import ReportTab from '../../components/report/ReportTab';
import OverviewTab from '../../section/company/employee/OverviewTab';
import PensionTab from '../../section/PensionTab';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

function EmployeeDetails() {
  const params = useParams();
  const [breadcrumb] = useState<IBreadCrumb[]>([
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Employee',
      href: '/employees',
    },
    { name: `${params.id}` },
  ]);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle={`${params.id}`} />
      <Tabs defaultActiveKey="overview" className="mb-3">
        <Tab eventKey="overview" title="Overview">
          <OverviewTab />
        </Tab>
        <Tab eventKey="pension" title="Pension">
          <PensionTab />
        </Tab>
        <Tab eventKey="reports" title="Reports">
          <ReportTab />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default EmployeeDetails;
