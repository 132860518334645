import React from 'react';

import { Col, Row } from 'react-bootstrap';

type Props = {
  pageTitle: string;
  showCalender?: boolean;
  showRefreshBtn?: boolean;
};

const ModalsHeader = ({ pageTitle }: Props) => {
  return (
    <Row>
      <Col xs="auto" className="d-none d-sm-block">
        <h3 className="mb-0">{pageTitle}</h3>
      </Col>
    </Row>
  );
};

export default ModalsHeader;
