import React from 'react';
import { CompanyClientDataType } from '../../../types/base/TableTypes';

type Props = {
  values: CompanyClientDataType['data'];
};

function TableCompanyClient({ values }: Props) {
  return (
    <React.Fragment>
      {values &&
        values.data.map((values, i) => {
          return (
            <tr key={i} className="table table-hover">
              <td className="d-none d-xl-table-cell">{values['companyName']}</td>
              <td className="d-none d-xl-table-cell">{values['organizationNumber']}</td>
              <td className="d-none d-xl-table-cell">{'-'}</td>
              <td className="d-none d-xl-table-cell">{'-'}</td>
              <td className="d-none d-xl-table-cell">{values['status']}</td>
              <td className="d-none d-xl-table-cell">{values['createdAt']}</td>
              <td className="d-none d-xl-table-cell">{values['updatedAt']}</td>
              {/* <td className="d-none d-xl-table-cell">
                <Form.Select className="p-2 border-0 w-75">
                  {(value.agreement as string[]).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </td> */}
            </tr>
          );
        })}
    </React.Fragment>
  );
}

export default TableCompanyClient;
