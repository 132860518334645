import React from 'react';

import { Card, Dropdown } from 'react-bootstrap';
import { MoreHorizontal } from 'react-feather';

type Props = { title?: string; children?: React.ReactNode };

function CardComponent({ title, children }: Props) {
  return (
    <Card className="flex-fill w-100">
      {title && (
        <Card.Header>
          <div className="card-actions float-end">
            <Dropdown align="end">
              <Dropdown.Toggle as="a" bsPrefix="-">
                <MoreHorizontal />
              </Dropdown.Toggle>
              {/* <Dropdown.Menu> */}
              {/* Dropdown Items here */}
              {/* <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item> */}
              {/* </Dropdown.Menu> */}
            </Dropdown>
          </div>
          <Card.Title className="mb-0">{title}</Card.Title>
        </Card.Header>
      )}
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

export default CardComponent;
