import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { getAdminCompaniesDetail, initializeSession, invokeToken } from '../../api/service/auth/service';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from '../../hooks/useLocalStorage';
import { AuthUser, UserRole } from '../../types/auth';

function SignInPage() {
  const { signIn } = useAuth();
  // eslint-disable-next-line
  const [userLocalStorage, setUserLocalStorage] = useLocalStorage('user', null);

  const [params] = useSearchParams();
  const [isLoading, SetIsLoading] = useState(false);
  const [showSigninBtn, setShowSigninBtn] = useState(false);
  const [signInMethod, setSigninMethod] = useState<string | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [userData, setUserData] = useState<AuthUser | null>(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isCompanyLoader, setIsCompanyLoader] = useState(false);
  const [companiesList, setCompaniesList] = useState<[] | Record<string, string>[]>([]);

  useEffect(() => {
    const initSession = params.get('initsession');
    const sessionId = params.get('grandidsession');

    const getToken = async (sessionId: string) => {
      try {
        SetIsLoading(true);
        setIsError(false);
        setErrorMsg(null);

        setShowSigninBtn(false);

        const { accessToken, user } = await invokeToken(sessionId);

        const newUser = {
          ...user,
          role: UserRole['COMPANY_ADMIN'],
          accessToken,
        };

        setUserData(newUser);
        setUserLocalStorage(newUser);
        setIsCompanyLoader(true);
      } catch (error) {
        if (error instanceof Error) {
          console.error('handlegetToken Error', error.message);
          setErrorMsg(error.message);
        }
      } finally {
        SetIsLoading(false);
      }
    };

    if (initSession && sessionId) {
      getToken(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, signIn]);

  useEffect(() => {
    const getUserCompaniesData = async () => {
      const companiesData = await getAdminCompaniesDetail(userData!['personalNumber']);

      setCompaniesList(companiesData);
    };

    if (userData && isCompanyLoader) {
      getUserCompaniesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanyLoader]);

  const handleInitSessionChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    SetIsLoading(true);
    setIsError(false);
    setErrorMsg(null);

    setShowSigninBtn(false);

    const method = e['target']['value'];
    setSigninMethod(method);

    try {
      const { redirectUrl } = await initializeSession(method);
      setRedirectUrl(redirectUrl);

      setShowSigninBtn(true);
    } catch (error) {
      setIsError(true);
      if (error instanceof Error) {
        console.error('handleSigninMethodChange Error', error.message);
        setErrorMsg(error.message);
      }
    } finally {
      SetIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const handleSelectCompanyAndLogin = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const company = e['target']['value'];
    const newUser: AuthUser = {
      ...userData!,
      company,
    };

    setUserData(newUser);
    setUserLocalStorage(newUser);
    signIn(newUser);
  };

  return (
    <div>
      {isLoading ? (
        <div className="mt-4 text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          {isCompanyLoader ? (
            <div className="text-center mt-3">
              {companiesList.length > 0 ? (
                <Form>
                  <Form.Group className="mt-4">
                    <Form.Label className="font-weight-bold">Companies Registered</Form.Label>
                    <Form.Select
                      name="signin-method"
                      placeholder="signin-method"
                      value={signInMethod ? signInMethod : ''}
                      onChange={handleSelectCompanyAndLogin}
                    >
                      <option value="" disabled>
                        Choose Your company
                      </option>

                      {companiesList.map((item, index) => {
                        return (
                          <option key={index} value={item['companyName']}>
                            {item['companyName']}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Form>
              ) : (
                <React.Fragment>
                  <p className="font-weight-bold">No Registered Company Found</p>
                  <Button type="submit" variant="primary" size="lg" onClick={() => signIn(userData)}>
                    Click here to proceed
                  </Button>
                </React.Fragment>
              )}
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-4">
                <Form.Label>Sign in Method</Form.Label>
                <Form.Select
                  name="signin-method"
                  placeholder="signin-method"
                  value={signInMethod ? signInMethod : ''}
                  onChange={handleInitSessionChange}
                >
                  <option value="" disabled>
                    Choose Signin Method
                  </option>
                  <option value="SAME_DEVICE">BankId on this device</option>
                  <option value="ANOTHER_DEVICE">BankId on another device</option>
                </Form.Select>
              </Form.Group>
              {isError && (
                <Alert variant="danger" className="mt-3 p-2">
                  {errorMsg}
                </Alert>
              )}
              {showSigninBtn && (
                <div className="text-center mt-3">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    onClick={() => {
                      window.open(redirectUrl ? redirectUrl : undefined, '_self');
                      SetIsLoading(true);
                    }}
                  >
                    Sign in
                  </Button>
                </div>
              )}
            </Form>
          )}
        </div>
      )}
    </div>
  );
}

export default SignInPage;
