import React from 'react';
import { Link } from 'react-router-dom';
// import { Form } from 'react-bootstrap';
import { CompanyEmployeesDataType } from '../../../types/base/TableTypes';

type Props = {
  values: CompanyEmployeesDataType['data'];
};

function TableCompanyEmployee({ values }: Props) {
  return (
    <React.Fragment>
      {values &&
        values.map((value, i) => (
          <tr key={i} className="table table-hover">
            <td className="d-none d-xl-table-cell">
              <Link to={`/employees/${value['name']}`}>{value['name']}</Link>
            </td>
            <td className="d-none d-xl-table-cell">{value['personalNumber']}</td>
            <td className="d-none d-xl-table-cell">{value['employeeCompany']['employmentStartDate']}</td>
            <td className="d-none d-xl-table-cell">{value['currentMonthlySalary']['amount']}</td>
            {/* <td className="d-none d-xl-table-cell"> */}
            {/* <Form.Select className="p-2 border-0 w-75"> */}
            {/* {(value.agreement as string[]).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))} */}
            {/* </Form.Select> */}
            {/* </td> */}
            <td className="d-none d-xl-table-cell">{value['agreement']['name']}</td>
            <td className="d-none d-xl-table-cell">{value['currentMonthlyPension']['pensionAmount']}</td>
          </tr>
        ))}
    </React.Fragment>
  );
}

export default TableCompanyEmployee;
