import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import CardComponent from '../base/CardComponent';
import PagesHeader from '../base/PagesHeader';

function PersonalInfo() {
  const [endDate, setEndDate] = useState('2018-07-22');
  return (
    <div>
      <PagesHeader pageTitle="Personal Info" />
      <CardComponent>
        <Form>
          <div className="d-flex mb-3 w-75">
            <Form.Group className="w-25">
              <Form.Label>Full Name</Form.Label>
              <Form.Control disabled placeholder="Enter Full Name" name="Full Name" value="John Doe" />
            </Form.Group>

            <Form.Group className="w-25">
              <Form.Label>Personal number</Form.Label>
              <Form.Control disabled placeholder="Enter Personal number" name="Personal number" value="19650110-1920" />
            </Form.Group>
          </div>

          <div className="d-flex mb-3 w-75">
            <Form.Group className="me-3 w-25">
              <Form.Label>Address </Form.Label>
              <Form.Control disabled placeholder="Enter Address" name="Address" value="Klarälvsvägen 98" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Postcode</Form.Label>
              <Form.Control disabled placeholder="Enter Postcode" name="Postcode" value="44 135-1920" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>City</Form.Label>
              <Form.Control disabled placeholder="Enter City" name="City" value="ALINGSÅS" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Country</Form.Label>
              <Form.Control disabled placeholder="Enter Country" name="Country" value="Sweden" />
            </Form.Group>
          </div>

          <div className="d-flex mb-3 w-75">
            <Form.Group className="me-3 w-25">
              <Form.Label>Start date </Form.Label>
              <Form.Control disabled type="date" placeholder="Enter Start Date" name="Start Date" value="2018-07-22" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>End date</Form.Label>
              <Form.Control
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                placeholder="Enter End Date"
                name="End Date"
                value={endDate}
              />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Employment number</Form.Label>
              <Form.Control disabled placeholder="Enter Employment number" name="Employment number" value="331" />
            </Form.Group>

            <Form.Group className="me-3 w-25">
              <Form.Label>Status</Form.Label>
              <Form.Control disabled placeholder="Enter Status" name="Status" value="Parental leave" />
            </Form.Group>
          </div>
        </Form>
      </CardComponent>
    </div>
  );
}

export default PersonalInfo;
