import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Copy, Edit2, FilePlus } from 'react-feather';
import { useParams, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/base/Breadcrumb';
import PagesHeader from '../../components/base/PagesHeader';
import { IBreadCrumb } from '../../types/base/BreadCrumb';
import PensionPoliciesDetailsEditable from './PensionPoliciesDetailsEditable';
import PensionPoliciesDetailsNonEditable from './PensionPoliciesDetailsNonEditable';

function PensionPolicyDetails() {
  const params = useParams();
  const [searchParam] = useSearchParams();
  const [isEdit] = useState(searchParam.get('edit'));
  const [breadcrumb] = useState<IBreadCrumb[]>([
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Pension Policies',
      href: '/pension-policies',
    },
    { name: `${params.name}` },
  ]);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <PagesHeader pageTitle={`${params.name}`} />
        <div className="d-flex gap-2">
          <Button variant="primary">
            <Edit2 className="icon-gap-right" />
            Edit Policy
          </Button>
          <Button variant="primary">
            <Copy className="icon-gap-right" />
            Duplicate Policy
          </Button>
          <Button variant="primary">
            <FilePlus className="icon-gap-right" />
            Add new Policy
          </Button>
        </div>
      </div>

      {isEdit === 'true' ? <PensionPoliciesDetailsEditable /> : <PensionPoliciesDetailsNonEditable />}
    </Container>
  );
}

export default PensionPolicyDetails;
