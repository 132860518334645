import React from 'react';
import { CompanyPensionCostDataType } from '../../../types/base/TableTypes';

type Props = {
  values: CompanyPensionCostDataType['data'];
};

function TableCompanyPensionCost({ values }: Props) {
  return (
    <React.Fragment>
      {values.data.map((item, i) => (
        <tr key={i}>
          <td>{item['pension company']}</td>
          <td>{item.apr}</td>
          <td>{item.may}</td>
          <td>{item.jun}</td>
          <td>{item.july}</td>
          <td>{item.aug}</td>
          <td>{item.sep}</td>
        </tr>
      ))}
      {values.total.map((item, i) => (
        <tr key={i} className="border border-primary shadow p-3 mb-5 bg-body rounded">
          <th className="text-dark">Total</th>
          <td>{item.apr}</td>
          <td>{item.may}</td>
          <td>{item.jun}</td>
          <td>{item.july}</td>
          <td>{item.aug}</td>
          <td>{item.sep}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default TableCompanyPensionCost;
