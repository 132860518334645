import React from 'react';
import { SuperAdminPensionPolicySalaryType } from '../../../types/base/TableTypes';

type Props = {
  values: SuperAdminPensionPolicySalaryType['data'];
};

function SuperAdminPensionPoliciesSalary({ values }: Props) {
  return (
    <React.Fragment>
      {values.data.map((item, i) => (
        <tr key={i}>
          <td>{item['salaryCode']}</td>
          <td key={i}>{item['name']}</td>
          <td>{item['salaryType']}</td>
          <td>{item['unit']}</td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default SuperAdminPensionPoliciesSalary;
